import {
    eachWeekendOfInterval,
    differenceInCalendarYears,
    getYear,
    addYears,
    isWeekend,
    isSameDay,
    isWithinInterval,
    addMinutes,
    isPast,
} from 'date-fns';
import Holidays from 'date-holidays';
import type { TFunction } from 'react-i18next';
import { OptionType } from '../../components/general/dropdown/DropDown';
import { useGetMenu } from './apiQueries/useMenu';
import { DeliveryMethod, FoodLine, OrderedFoodType, FoodMenu, orderedFoodLine, OrderStatus } from './types';

export const getItemPrice = (
    { taxPriceWithoutTableService, taxPriceWithTableService, priceNet }: FoodLine,
    deliveryMethod: DeliveryMethod,
): number => {
    if (deliveryMethod == 'hente' || deliveryMethod === 'levering') {
        return priceNet + taxPriceWithoutTableService;
    } else if (deliveryMethod === 'oppdekning') {
        return priceNet + taxPriceWithTableService;
    }
    throw 'unexpected deliveryMethod';
};

export const calculateGrossSum = (
    deliveryMethod: DeliveryMethod,
    foodLines: orderedFoodLine[],
    participants = 0,
    deliveryFeeGross = 0,
    setTableFeeGross = 0,
): number => {
    let sum = foodLines.reduce(
        (curr, foodLine) => curr + getItemPrice(foodLine, deliveryMethod) * foodLine.quantity,
        0,
    );
    if (deliveryMethod === 'levering' || deliveryMethod === 'oppdekning') {
        sum += deliveryFeeGross;
    }
    if (deliveryMethod === 'oppdekning') {
        sum += setTableFeeGross * participants;
    }
    return sum;
};

// If ordered item has a deadline thats further away then selected delivery time
export const foodLinesContainsInvalidItem = (foodLines: orderedFoodLine[]): boolean => {
    const now = new Date();
    const foundInvalid = foodLines.find(
        (foodLine) => foodLine.orderDeadline < now || foodLine.unavailable || foodLine.isPassedDeadline,
    );
    return foundInvalid !== undefined;
};

export const getOrderStatus = (order: OrderedFoodType): OrderStatus => {
    const now = new Date();
    if (order.orderStatus === 'canceled') {
        return 'canceled';
    } else if (new Date(order.deliveryDateTime) < now) {
        return 'delivered';
    } else {
        return 'active';
    }
};

const hd = new Holidays();
hd.init('NO', {
    types: ['public'],
});
export const getHolidaysAndWeekends = (): Date[] => {
    const minDate = new Date();
    const maxDate = addYears(minDate, 1);

    const weekendsInInterval = eachWeekendOfInterval({
        start: minDate,
        end: maxDate,
    });
    let holidaysSecondYear = [] as Holidays.Holiday[];

    const holidaysFirstYear = hd.getHolidays(getYear(minDate));
    if (differenceInCalendarYears(maxDate, minDate) > 0) {
        holidaysSecondYear = hd.getHolidays(getYear(maxDate));
    }

    const firstYearHolidaysDates = holidaysFirstYear.map((h: Holidays.Holiday) => h.start);
    const secondYearHolidaysDates = holidaysSecondYear.map((h: Holidays.Holiday) => h.start);

    return [...weekendsInInterval, ...firstYearHolidaysDates, ...secondYearHolidaysDates];
};

export const isHoliday = (date: Date): boolean => hd.isHoliday(date) !== false;

export const getInvalidMeetingMessage = (
    startTime: Date,
    endTime: Date,
    departmentBusinessHoursFrom: Date,
    departmentBusinessHoursTo: Date,
    minDate: Date,
    t: TFunction<'Food'>,
): string => {
    if (isPast(startTime)) return '';
    if (minDate > endTime) {
        return t('commonFood.meetingIsTooCloseInTime');
    }
    if (isMeetingOutsideOpeningHours(departmentBusinessHoursFrom, departmentBusinessHoursTo, startTime, endTime)) {
        return t('commonFood.meetingOutsideCanteensOpeningHours');
    }
    if (hd.isHoliday(startTime)) {
        return t('commonFood.meetingIsOnPublicHolidayCanteenClosed');
    }
    if (isWeekend(startTime)) {
        return t('commonFood.canteenNotOpenOnWeekends');
    }
    return '';
};

const getSecondsOfDay = (time: Date): number => {
    return time.getSeconds() + time.getMinutes() * 60 + time.getHours() * 60 * 60;
};

const isMeetingOutsideOpeningHours = (openFrom: Date, openTo: Date, startTime: Date, endTime: Date): boolean => {
    const openFromSeconds = getSecondsOfDay(openFrom);
    const openToSeconds = getSecondsOfDay(openTo);
    const startSeconds = getSecondsOfDay(startTime);
    let endTimeSeconds = getSecondsOfDay(endTime);
    if (endTimeSeconds === 0 && !isSameDay(startTime, endTime)) {
        // Meeting is lasting 24 hours
        endTimeSeconds = 24 * 60 * 60;
    }

    return endTimeSeconds <= openFromSeconds || startSeconds >= openToSeconds;
};

export const isTimeOutsideOpeningHours = (time: Date, openFrom: Date, openTo: Date): boolean => {
    const copyFrom = new Date(openFrom);
    copyFrom.setFullYear(time.getFullYear(), time.getMonth(), time.getDate());
    const copyTo = new Date(openTo);
    copyTo.setFullYear(time.getFullYear(), time.getMonth(), time.getDate());
    return isWithinInterval(time, { start: copyFrom, end: copyTo });
};

export const getNonCompatibleDeliveryDateTimeLines = (
    foodLines: orderedFoodLine[],
    menu: FoodMenu,
): orderedFoodLine[] => {
    const menuLines = menu.foodGroups.reduce((curr, group) => [...curr, ...group.foodLines], [] as FoodLine[]);
    const mappedFoodLines = foodLines.map((line) => {
        const inMenu = menuLines.find((item) => item.foodLineId === line.foodLineId);
        if (!inMenu) {
            console.error('Could not find foodline in menu!', line);
        }
        return { ...line, ...inMenu };
    });
    return mappedFoodLines.filter((mappedLine) => mappedLine.orderDeadline < new Date());
};

export const getReadableListString = (list: string[]): string => {
    if (list.length == 0) return '';
    if (list.length == 1) return list[0];
    const allButLast = list.slice(0, -1);
    const value = allButLast.join(', ');
    return `${value} og ${list[list.length - 1]}`;
};

export const getTimeOptions = (earliestTime: Date, latestTime: Date): Date[] => {
    const startTime = new Date(earliestTime);
    startTime.setSeconds(0);
    startTime.setMilliseconds(0);
    const endTime = new Date(latestTime);

    // If startTime is not whole 15 minutes
    if (startTime.getMinutes() % 15 !== 0) {
        startTime.setMinutes(startTime.getMinutes() + 15 - (startTime.getMinutes() % 15));
    }

    const result = [];
    let current = startTime;
    while (current.valueOf() <= endTime.valueOf()) {
        result.push(current);
        current = addMinutes(current, 15);
    }
    return result;
};

export const getCanteenList = (): OptionType[] | [] => {
    const data = useGetMenu(null).data?.canteens;
    const options = data?.map((value) => ({
        id: value,
        value: value,
    }));
    return options ?? [];
};

export const roundTimeToNearestQuarterHour = (date: Date): Date => {
    const minutes = date.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;

    date.setMinutes(roundedMinutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
};

export const roundToNearest15Minutes = (time: Date): Date => {
    const minutes = time.getMinutes();
    const roundedMinutes = Math.ceil(minutes / 15) * 15;
    if (roundedMinutes === 60) {
        time.setMinutes(0);
        time.setHours(time.getHours() + 1);
    } else {
        time.setMinutes(roundedMinutes);
    }
    time.setSeconds(0);
    time.setMilliseconds(0);

    return time;
};
