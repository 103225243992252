import { ReactElement, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TFunction, useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import type { DailyMenuWithAllergensTypeAndDate } from '../../../../../api/apiTypes/cmsApiTypes';
import { ErrorMessage } from '../../../../../components';
import { useGetProductsAllergens } from '../../../../ShopAtWork/apiQueries/useShopProducts';
import { useUpdateDailyMenu } from '../../useDailyMenu';
import DayMenuInput from './DayMenuInput';

const ContainerWrapper = styled.form`
    display: grid;
    grid-gap: 1.71rem;
    width: 100%;
    font-family: Lato, Helvetica Neue, Arial, Helvetica, sans-serif;
    margin-top: 1.71rem;
`;

const TITLE_MAX_LENGTH = 255;

const schema = (tFunction: TFunction) =>
    yup.object().shape({
        menus: yup.array().of(
            yup.object().shape({
                title: yup.string().max(TITLE_MAX_LENGTH, tFunction('titleMaxLengthError')),
            }),
        ),
    });

interface MenuFormProps {
    menus: DailyMenuWithAllergensTypeAndDate[];
    formId: string;
    setIsSaving(isSaving: boolean): void;
    setFormIsDirty(isDirty: boolean): void;
    setIsSuccess(isSuccess: boolean): void;
    setIsError(isError: boolean): void;
    showNavDisabledError: boolean;
}

interface CreateMenuForm {
    menus: DailyMenuWithAllergensTypeAndDate[];
}
const MenuForm = ({
    menus,
    formId,
    setIsSaving,
    setIsSuccess,
    setFormIsDirty,
    setIsError,
    showNavDisabledError,
}: MenuFormProps): ReactElement => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'dailyMenu' });
    const { data: allAllergens, isLoading: isAllergensLoading } = useGetProductsAllergens();

    const [createFoodMenu, { isLoading: isSaving, isError, isSuccess }] = useUpdateDailyMenu();
    const {
        register,
        handleSubmit,
        formState: { errors, isDirty },
        watch,
        reset,
        control,
    } = useForm<CreateMenuForm>({
        resolver: yupResolver(schema(t)),
        mode: 'onChange',
        defaultValues: {
            menus,
        },
    });

    const onSubmit = ({ menus }: CreateMenuForm) => {
        reset({ menus });
        createFoodMenu(menus);
    };

    useEffect(() => {
        setIsSaving(isSaving);
    }, [isSaving, setIsSaving]);

    useEffect(() => {
        setFormIsDirty(isDirty);
    }, [isDirty, setFormIsDirty]);

    useEffect(() => {
        setIsSuccess(isSuccess);
    }, [isSuccess, setIsSuccess]);

    useEffect(() => {
        const hasErrors = Object.keys(errors).length > 0;
        setIsError(hasErrors);
    }, [errors.menus, setIsError]);

    return (
        <ContainerWrapper id={formId} onSubmit={handleSubmit(onSubmit)}>
            {showNavDisabledError ? <ErrorMessage message={t('errorMessageForUnsavedChanges')} /> : null}
            <p>{t('menuFormTitle')}</p>
            {isError ? <ErrorMessage message={t('errorMessageWhileSavingTheMenu')} /> : null}
            <div>
                {menus.map(({ date, title }, i) => (
                    <DayMenuInput
                        key={date.valueOf()}
                        date={date}
                        index={i}
                        register={register}
                        watch={watch}
                        defaultTitle={title}
                        control={control}
                        allergens={isAllergensLoading ? [] : allAllergens ?? []}
                        error={errors?.menus?.[i]}
                    />
                ))}
            </div>
        </ContainerWrapper>
    );
};

export default MenuForm;
