import { ReactElement, useMemo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Ticket, TicketType } from '../../../../../../api/apiTypes/ticketingApiTypes';
import {
    Modal,
    PrimaryButton,
    InputSelect,
    Dropdown,
    InformationMessage,
    ModalType,
} from '../../../../../../components';
import { useUpdateTicket } from '../../../../apiQueries/useTicketingTicket';
import { Paragraph } from '../../../../common/styles';
import { getPublishedCategoryOptions } from '../utils/utils';

const StyledModal = styled(Modal)`
    #modalContentContainer {
        overflow: visible;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const ContentContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.7rem;
`;

const StyledInputSelect = styled(InputSelect)`
    padding-bottom: 1.286rem;
`;

const StyledDropdown = styled(Dropdown)`
    padding-bottom: 1.286rem;
`;

const ErrorMessage = styled(InformationMessage)`
    padding: 1rem 0;
`;

interface ChangeTypeModalProps {
    ticket: Ticket;
    ticketTypes: TicketType[] | [];
    open: boolean;
    onClose(): void;
    onUpdateSuccess(newTypeName: string, newCategoryId: string): void;
}

const ChangeTypeModal = ({
    ticket,
    ticketTypes,
    open,
    onClose,
    onUpdateSuccess,
}: ChangeTypeModalProps): ReactElement => {
    const { t } = useTranslation('Ticketing', { keyPrefix: 'commonTicketing' });
    const [selectedCategory, setSelectedCategory] = useState(ticket.category.id);
    const [selectedType, setSelectedType] = useState({ id: ticket.type.id, name: ticket.type.name });
    const [updateTicket, { isLoading: isUpdatingTicket, isError: isErrorUpdatingTicket }] = useUpdateTicket();

    const publishedTypesOptions = useMemo(() => {
        return ticketTypes
            .filter((type) => type.isPublished)
            .map((type) => ({
                id: type.id,
                label: type.name,
            }));
    }, [ticketTypes]);

    const categoryOptions = useMemo(() => {
        return getPublishedCategoryOptions(ticketTypes, selectedType.id);
    }, [selectedType, ticketTypes]);
    useEffect(() => setSelectedCategory(''), [selectedType.id]);

    useEffect(() => {
        setSelectedType({ id: ticket.type.id, name: ticket.type.name });
        setSelectedCategory(ticket.category.id);
    }, [ticket]);

    const onClickChangeHandler = () => {
        updateTicket(
            [
                ticket.id,
                {
                    newTypeId: selectedType.id,
                    newCategoryId: selectedCategory,
                },
            ],
            { onSuccess: () => onUpdateSuccess(selectedType.name, selectedCategory) },
        );
    };

    return (
        <StyledModal
            title={t('changeTypeModalTitle')}
            onClose={onClose}
            open={open}
            type={ModalType.Small}
            bottomContent={
                <ButtonContainer>
                    <PrimaryButton
                        onClick={onClickChangeHandler}
                        disabled={
                            !selectedType.id ||
                            !selectedCategory ||
                            !categoryOptions.some((category) => category.id == selectedCategory) ||
                            isUpdatingTicket
                        }
                    >
                        {t('changeTypeModalButtonChange')}
                    </PrimaryButton>
                </ButtonContainer>
            }
        >
            <ContentContainer>
                {isErrorUpdatingTicket && (
                    <ErrorMessage key="changeTypeModalError" type="error">
                        {t('changeTypeModalErrorMessage')}
                    </ErrorMessage>
                )}
                <Paragraph>{t('changeTypeModalSelectInquiryType')}</Paragraph>
                <StyledInputSelect
                    initialValue={{ id: selectedType.id, label: selectedType.name }}
                    name="newType"
                    isClearable
                    onChange={(selectedValue) => {
                        if (selectedValue) {
                            setSelectedType({
                                id: selectedValue.id,
                                name: selectedValue.label,
                            });
                        }
                    }}
                    id="newType"
                    options={publishedTypesOptions}
                    placeholder={t('changeTypeModalSearchByTypePlaceholder')}
                    showSearchIcon
                />
                <StyledDropdown
                    key="newCategory"
                    items={categoryOptions}
                    onChange={(categoryName) => setSelectedCategory(categoryName)}
                    required
                    label=""
                    placeholder={t('changeTypeModalCategoryPlaceholder')}
                    value={selectedCategory}
                    name="newCategory"
                    initialValue={selectedCategory}
                    isScrollable={true}
                />
                <Paragraph>{t('changeTypeModalCategoryParagraph')}</Paragraph>
            </ContentContainer>
        </StyledModal>
    );
};

export default ChangeTypeModal;
