import type { AxiosError } from 'axios';
import { nextFriday, startOfWeek } from 'date-fns';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import type { DailyMenuWithAllergens, DailyMenuWithAllergensTypeAndDate } from '../../../api/apiTypes/cmsApiTypes';
import type { AllergenType } from '../../../api/apiTypes/shopApiTypes';
import { getDailyMenus, updateFoodMenu } from '../../../api/cmsApi';
import { getAllergens } from '../../../api/shopApi';

const dailyMenusKey = 'daily-menus';

interface DailyMenusAndAllergens {
    menus: DailyMenuWithAllergens[];
    allAllergens: AllergenType[];
}

const mapDailyMenusWithAllergens = ({ menus, allAllergens }: DailyMenusAndAllergens) => {
    return menus.map(({ title, description, date, allergens }) => ({
        title,
        description,
        allergens: allergens
            ? allergens.map((allergenId) => {
                  const allergenCandidate = allAllergens.find((allergen) => allergen.id === allergenId);
                  return (
                      allergenCandidate ?? {
                          id: allergenId,
                          name: allergenId,
                      }
                  );
              })
            : [],
        date: new Date(date),
    }));
};

export const useDailyMenus = (
    monday = startOfWeek(new Date(), { weekStartsOn: 1 }),
): QueryResult<DailyMenuWithAllergensTypeAndDate[], string> => {
    return useQuery(
        [dailyMenusKey, monday],
        async () => {
            const friday = nextFriday(monday);

            const { data: menus } = await getDailyMenus(monday, friday);
            const { data: allAllergens } = await getAllergens();

            return mapDailyMenusWithAllergens({ menus, allAllergens });
        },
        { staleTime: 1000 * 60 * 60 },
    );
};

export const useUpdateDailyMenu = (): MutationResultPair<
    DailyMenuWithAllergensTypeAndDate[],
    Error | AxiosError<string>,
    DailyMenuWithAllergensTypeAndDate[],
    never
> => {
    const cache = useQueryCache();

    return useMutation<
        DailyMenuWithAllergensTypeAndDate[],
        Error | AxiosError<string>,
        DailyMenuWithAllergensTypeAndDate[],
        never
    >(
        async (dto) => {
            const { data: menus } = await updateFoodMenu(
                dto.map(({ date, allergens, ...rest }) => ({
                    ...rest,
                    date: date.toISOString(),
                    allergens: allergens.map((allergen) => allergen.id),
                })),
            );
            const { data: allAllergens } = await getAllergens();

            return mapDailyMenusWithAllergens({ menus, allAllergens });
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(dailyMenusKey);
            },
        },
    );
};
