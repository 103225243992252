import { ModuleName } from '../../api/apiTypes/portalApiTypes';
import { Perm } from '../../common';
import useModuleIsActive from './useModuleIsActive';
import useUserHasPerm from './useUserHasPerm';

const useHasConferenceMealAccess = (): boolean => {
    const hasEmailFoodModule = useModuleIsActive(ModuleName.EmailFoodModule);
    const hasIntegrationModule = useModuleIsActive(ModuleName.IntegrationModule);
    const hasFoodForMeetingsModule = useModuleIsActive(ModuleName.FoodForMeetings);
    const userHasFoodRequirentPerm = useUserHasPerm(Perm.FoodRequirent);

    return userHasFoodRequirentPerm && hasFoodForMeetingsModule && (hasEmailFoodModule || hasIntegrationModule);
};

export default useHasConferenceMealAccess;
