import { ReactElement } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { MobileViewBreakpoint } from '../../../common/ScreenSizeBreakPoints';

const TabsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: 1rem;
    @media (max-width: ${MobileViewBreakpoint}px) {
        padding: 0 0 2.179rem;
    }
`;

const TabsNavigation = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    max-width: 100%;
`;

const CloseButton = styled(Button)`
    &&& {
        padding: 0.571rem;
        margin: 0;
        border-radius: unset;
        background-color: transparent;
    }
`;

const CloseButtonIcon = styled(Icon)`
    &&& {
        color: #959595;
        margin: 0 !important;
    }
`;

const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 0.1rem;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */

    ::-webkit-scrollbar {
        display: none; /* chrome and safari */
    }
`;

const HorizontalRule = styled.hr`
    border: 1px solid #d4d6d7;
    margin: 0;
`;

type StyledTabProps = { disabled: boolean };
const StyledTab = styled.button`
    font-weight: ${({ disabled }: StyledTabProps) => (disabled ? 'bold;' : 'normal;')};
    font-size: 1rem;
    color: ${({ disabled }: StyledTabProps) => (disabled ? '#263238;' : '#677074;')};
    white-space: nowrap;
    background-color: var(--background-color);

    && {
        border-bottom: ${({ disabled }: StyledTabProps) =>
            disabled
                ? `
             2px solid var(--primary-color) ;
           `
                : 'gray;'};
    }

    padding: 0.7rem 1.143rem;
    border: none;
    cursor: pointer;
    text-transform: capitalize;

    :focus-visible,
    :focus {
        outline: 1px solid var(--primary-color);
    }

    :focus:not(:focus-visible) {
        outline: none;
    }
`;

export type Tab = {
    id: string;
    label: string;
};

interface TabsProps {
    selectedTab: Tab;
    tabs: Tab[];
    onClickTab({ id, label }: Tab): void;
    onCloseClick?: (e: MouseEvent) => void;
}

const Tabs = ({ selectedTab, tabs, onClickTab, onCloseClick }: TabsProps): ReactElement => {
    return (
        <TabsWrapper>
            <TabsNavigation>
                <TabsContainer>
                    {tabs.map((tab) => (
                        <StyledTab
                            onClick={() => onClickTab(tab)}
                            id={tab.id}
                            key={tab.id}
                            disabled={tab.id === selectedTab.id}
                        >
                            {tab.label}
                        </StyledTab>
                    ))}
                </TabsContainer>
                {onCloseClick && (
                    <CloseButton onClick={onCloseClick}>
                        <CloseButtonIcon name="close" />
                    </CloseButton>
                )}
            </TabsNavigation>
            <HorizontalRule />
        </TabsWrapper>
    );
};

export default Tabs;
