import { ErrorInfo } from 'react';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { getApplicationInsightsConnectionString } from '../../utility/envReader';

const reactPlugin = new ReactPlugin();

function init(): void {
    if (process.env.NODE_ENV === 'development') return;
    const history = createBrowserHistory();
    const appInsights = new ApplicationInsights({
        config: {
            extensions: [reactPlugin],
            connectionString: getApplicationInsightsConnectionString(),
            extensionConfig: {
                [reactPlugin.identifier]: { history: history },
            },
        },
    });
    appInsights.loadAppInsights();
}

function log(error: Error, info?: ErrorInfo): void {
    reactPlugin.trackException({
        exception: error,
        severityLevel: SeverityLevel.Error,
        properties: { ...info },
    });
}

export default { init, log };
