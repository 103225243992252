import { BuildingsWithAvailableRoomsParams } from './bookingApiTypes';

export interface CompanyActivationSettings {
    company: {
        name: string;
        organizationNumber: string;
        street: string;
        zipCode: string;
        city: string;
        country: string;
    };
    contactPerson: {
        firstName: string;
        lastName: string;
        email: string;
        phone?: string;
    };
}

interface Employee {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    companyName?: string;
}

interface Company {
    id: string;
    name: string;
}

export interface OrderLine {
    id: string;
    productId: string;
    title: string;
    internalProductId: string;
    amount: number;
    description: string;
    priceOneItem: number;
    totalPrice: number;
    taxOneItemPercent: number;
}

export enum UnavailabilityStatusCode {
    ProductIsNotDeliverable = 0,
    ProductIsNotInBusinessHours = 10,
    VendorIsNotInBusinessHours = 20,
    OrderNotMatchesProductOrderingDeadlineTime = 30,
    OrderNotMatchesProductOrderingDeadlineInDays = 40,
    NotEnoughTimeToPrepareOrder = 50,
    VendorCantDeliverToSpecifiedMeetingRoom = 60,
    VendorDoesNotHaveAnyAvailableProducts = 70,
}

export interface OrderLineV2 {
    id: string;
    title: string;
    amount: number;
    description: string | null;
    priceOneItem: number;
    productId: string;
    totalPrice: number;
    taxOneItemPercent: number;
}

interface OrderVendor extends Omit<FinancialDetails, 'id' | 'country'> {
    pointOfSale: string;
}

interface FinancialDetails {
    id: string;
    name: string;
    organizationNumber: string;
    country: string | null;
    zipCode: string | null;
    street: string | null;
    city: string | null;
}

export enum OrderLineTitle {
    Lunch = 'Lunch',
}

export enum OrderStatusOption {
    Ordered = 'Ordered',
    Delivered = 'Delivered',
    Canceled = 'Canceled',
    Deleted = 'Deleted',
    Approved = 'Approved',
}

export enum VendorOrderStatusCode {
    Ordered = 0,
    Delivered = 10,
    Canceled = 20,
}

export enum VendorOrderStatusOption {
    Ordered = 'Ordered',
    Delivered = 'Delivered',
    Canceled = 'Canceled',
}

export enum OrderStatusOptionV2 {
    Ordered = 'Ordered',
    Delivered = 'Delivered',
    Canceled = 'Canceled',
    Deleted = 'Deleted',
}

interface ContactPerson {
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string;
    phone: string;
}

export enum ProductStatusOption {
    Active = 'Active',
    Disabled = 'Disabled',
}

export enum ProductTypes {
    General = 'Generelt',
    Spaces = 'Lokaler',
    Food = 'Mat',
    Services = 'Tjenester',
}

export interface ProductTypeOption {
    id: string;
    name: ProductTypes;
}

export interface ProductCategoryOption {
    id: string;
    name: string;
}

export interface ProductTypeOptionWithCount extends ProductTypeOption {
    productsCount: number;
}

export interface ProductCategoryOptionWithCount extends ProductCategoryOption {
    countProducts: number;
}

export interface EmployeeOption {
    id: string;
    name: string;
}

export interface CompanyOption {
    id: string;
    name: string;
}

export enum PaymentOption {
    CustomerInvoice = 5,
    Stripe = 10,
}

export const PAYMENT_OPTION_TITLE_TRANSLATION_KEY = {
    [PaymentOption.CustomerInvoice]: 'Invoice',
    [PaymentOption.Stripe]: 'Card',
};

export enum ProductStatus {
    Active = 0,
    Disabled = 10,
}
export type PaymentTypes = keyof typeof PaymentOption;

export enum LunchFlowType {
    Order = 0,
    Scan = 10,
}

export enum ProductSortDirection {
    Asc = 0,
    Desc = 1,
}

export enum ProductSortingType {
    Name = 0,
    Status = 10,
    ProductType = 20,
    Category = 30,
    Price = 40,
    Supplier = 50,
    CreationDate = 60,
}

export enum ProductFilterType {
    Status = 0,
    Type = 10,
    Vendor = 20,
    Delivery = 30,
    DeliveryTime = 40,
    Category = 50,
    Menu = 60,
    MenuType = 70,
    Allergen = 80,
}

export enum MenuType {
    Lunch = 0,
    FoodForMeeting = 10,
    Kiosk = 20,
}

export enum ShopType {
    General = 'Generelt',
    Services = 'Tjenester',
    Food = 'Mat',
    Premises = 'Lokaler',
}

export interface ProductFilterTypes {
    type: ProductFilterType;
    value: string[];
}

export interface OrderV2 {
    id: string;
    orderNumber: number;
    externalId: string | null;
    status: OrderStatusOptionV2;
    paymentType: PaymentOption;
    discount: number;
    tax: number;
    totalExcludingTax: number;
    total: number;
    createdDate: string;
    updatedDate: string;
    orderLines: OrderLineV2[];
    orderReference: string | null;
    verificationAnimationUrl: string | null;
    verificationQRCode: string | null;
    deliveryDateTime: string | null;
    deliveryLocation: string | null;
    pickupLocation: string | null;
    vendor?: OrderVendor | null;
    contactEmail: string | null;
    comment: string | null;
}
export interface Order {
    id: string;
    orderNumber: number;
    status: OrderStatusOption;
    paymentType: PaymentOption;
    discount: number;
    tax: number;
    totalExcludingTax: number;
    total: number;
    createdDate: string;
    updatedDate: string;
    deliveryDateTime: string;
    deliveryLocation: string;
    comment: string;
    /**
     * The first element is always the user's order, followed by guest orders.
     */
    orderLines: [OrderLine, ...OrderLine[]];
    verificationAnimationUrl: string | null;
}

interface BusinessHours {
    dayOfWeek: number;
    startTime: string;
    endTime: string;
}

interface InvoiceCreatedBy {
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    phone: string;
}

export interface Invoice {
    contactPersonEmail: string;
    contactPersonFirstName: string;
    contactPersonLastName: string;
    contactPersonPhone: string;
    customerAddress: string;
    customerCountry: string;
    customerName: string;
    customerOrganizationNumber: number;
    dueDate: string;
    externalNumber: string;
    facturaNumber: string;
    orderReference: string;
    order: Order;
    paymentCondition: number;
    portalAddress: string;
    portalCountry: string;
    portalId: number;
    portalName: string;
    portalOrganizationNumber: number;
    status: number;
    total: number;
    createdBy: InvoiceCreatedBy;
}

export type UserOrder = Order;

export type UserOrderV2 = OrderV2;

export type UserOrderForKitchenAdmin = Order & {
    orderReference: string;
    verificationAnimationUrl: string;
    verificationQRCode: string;
    deliveryDateTime: Date;
    deliveryLocation: string;
    pickupLocation: string;
    comment: string;
    canEdit: boolean;
    canCancel: boolean;
    employee: Employee;
    company: Company;
    canteenName: string | null;
    menuType: GboMenuType;
};

export interface Vendor {
    city?: string;
    name: string;
    organizationNumber: string;
    street?: string;
    zipCode?: string;
}

export interface VendorV2 {
    id: string;
    name: string;
    description: string | null;
    imageUrl: string | null;
    externalVendorId: string;
    companyId: string | null;
    portalId: string;
    businessHours: BusinessHours[];
    paymentPublishableKey: string | null;
    financialDetails: FinancialDetails | null;
    contactPerson: ContactPerson | null;
    vendorAvailability: VendorAvailability;
    deliveryFeeGross: number;
    feeVatPercent: number;
    hasQRCodeValidation: boolean;
    hasSetTableService: boolean;
    paymentTypes: PaymentOption[];
    pickupLocations: string[];
    serviceFeeGross: number;
    type: ShopType;
    lunchFlowType: LunchFlowType;
    isOrderSingleProductRestrictionEnabled: boolean;
}

export interface CreateOrderLineModel {
    productGuid: string;
    amount: number;
}

export interface InvoiceForm {
    title?: string;
    reason?: string;
    participants?: string;
    costCode?: string;
    poNumber?: string;
}

export interface Allergen {
    id: string;
    name: string;
}

export interface PayLunchIdentification {
    qrCode: string;
    externalCustomerAccountLoaderUrl: string;
}

export interface CompanyOrder extends Order {
    employee: Employee;
    vendor?: Vendor;
}

export interface CompanyOrderV2 extends OrderV2 {
    employee: OrderEmployee;
    canEdit: boolean;
    canCancel: boolean;
    vendor?: OrderVendor;
}

export interface PortalOrder extends Order {
    company: Company;
}

/**
 * Copied from the native app
 */
export interface PortalOrderV2 extends OrderV2 {
    company: Company;
}

/**
 * Copied from the native app
 */
export interface ProductV2 {
    canteenId: string;
    id: string;
    portalId: number;
    title: string;
    price: number;
    priceWithTableService: number;
    taxPercent: number;
    taxPercentWithTableService: number;
    paymentType: PaymentOption;
    status: ProductStatus;
    description: string | null;
    imageUrl: string | null;
    productId: string;
    externalID: string;
    stock: number;
    category: ProductCategoryV2 | null;
    vendorName: string;
    components: Component[] | null;
    delivery: boolean;
    productAvailability: ProductAvailability;
    businessHours: BusinessHours[];
    priceUnit: string | null;
}

interface VendorAvailability {
    vendorId: string;
    isAvailable: boolean;
    unavailabilityStatus: UnavailabilityStatusCode | null;
}

export interface Product {
    id: string;
    portalId: number;
    title: string;
    price: number;
    taxPercent: number;
    paymentType: number;
    status: ProductStatus;
    description: string;
    imageUrl: string | null;
    productId: string;
    externalID: string;
    stock: number;
    category: ProductCategoryV2;
    canteenName: string;
    components?: ComponentType[];
    // TODO after deploy to production frontend and backend parts of allergens type since we'll use components only
    allergens?: string[];
    productAvailability: ProductAvailability;
}

export interface CanteenBusinessHours {
    dayOfWeek: number;
    startTime: string;
    endTime: string;
}

export interface CanteenV2 {
    id: string;
    name: string;
    imageUrl: string | null;
    businessHours: BusinessHours[];
    contactPerson: ContactPerson | null;
    paymentTypes: PaymentOption[];
    city: string | null;
    country: string | null;
    email: string | null;
    phone: string | null;
    zipCode: string;
    street: string | null;
}

export interface Canteen {
    name: string;
    imageUrl: string;
    email: string;
    phone: string;
    country: string;
    zipCode: number;
    street: string;
    city: string;
    businessHours: CanteenBusinessHours[];
    id: string;
}

export interface LunchProductV2 {
    id: string;
    canteenName: string;
    category: ProductCategoryV2 | null;
    name: string;
    description: string;
    imageUrl: string | null;
    price: number;
    productId: string;
    taxPercent: number;
    canteen: CanteenV2;
}
export interface LunchProduct {
    productId: string;
    canteen: Canteen;
    id: string;
    name: string;
}

export interface ProductCategory {
    id: string;
    name: string;
    description: string;
    type: ProductCategoryType;
    isDeleted: boolean;
}

export interface ProductTypeV2 {
    id: string;
    name: string;
    predefinedType: number;
    imageUrl: string | null;
}

export interface ProductCategoryV2 {
    id: string;
    name: string;
    imageUrl: string | null;
    type: ProductTypeV2;
}

export interface Component {
    id: string;
    name: string;
    type: number;
}

export interface ProductAvailability {
    productId: string;
    preparationTimeInMinutes: number;
    orderingDeadlineInDays: number;
    orderingDeadlineTimeOfDay: {
        ticks: number;
        days: number;
        hours: number;
        milliseconds: number;
        minutes: number;
        seconds: number;
        totalDays: number;
        totalHours: number;
        totalMilliseconds: number;
        totalMinutes: number;
        totalSeconds: number;
    };
    isAvailable: boolean;
    unavailabilityStatusCode: UnavailabilityStatusCode | null;
    minOrderQuantity: number | null;
}

export type ProductCategoryWithSubCategories = ProductTypeOptionWithCount & {
    childElements: ProductCategoryOptionWithCount[];
};

export interface NewProductCategory {
    typeId: string;
    name: string;
}

export interface UpdateProductCategoryDto {
    name: string;
    id: string;
}

export interface UpdateProductCategoryResponse {
    type: {
        id: string;
        name: string;
    };
    id: string;
    name: string;
}

export interface ProductCategoryType {
    id: string;
    name: ProductTypes;
    description: string;
    taxPercent: number;
    isDeleted: boolean;
}

export type GeneralOrder = UserOrder & CompanyOrder & PortalOrder;

export type GeneralOrderV2 = UserOrderV2 & CompanyOrderV2 & PortalOrderV2;

export interface OrdersWithCount {
    data: GeneralOrderV2[];
    count: number;
}

export interface IAttachedFoodOrders {
    id: string;
    orderNumber: number;
    externalId: null | number;
    status: OrderStatusOption;
    paymentType: string;
    discount: number;
    tax: number;
    totalExcludingTax: number;
    total: number;
    createdDate: string;
    updatedDate: string;
    orderLines: OrderLineV2[];
    orderReference: null | string;
    verificationAnimationUrl: null | string;
    verificationQRCode: null | string;
    deliveryDateTime: string;
    deliveryLocation: string;
    pickupLocation: null | string;
}

export enum UpdateOrderItemActionsEnum {
    NotSpecified = 0,
    Delete = 10,
    Update = 20,
}

export interface UpdateOrderLineModel {
    id: string;
    productId: string;
    amount: number;
    action: UpdateOrderItemActionsEnum;
}

export interface UpdateOrderDto {
    orderId: string;
    updateOrderLineModels: UpdateOrderLineModel[];
    comment: string;
}

export interface PurchaseGridResult {
    data: GeneralOrder[];
    count: number;
}

export interface UserOrderGridResult {
    data: UserOrderForKitchenAdmin[];
    count: number;
}

export enum OrderSortDirection {
    Asc = 0,
    Desc = 1,
}

export interface OrderFilterTypes {
    type: PurchasesFilterType | InventoryFilterType;
    value: string[];
}

interface OrderEmployee {
    id: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    companyName: string;
}

export enum InventorySortDirection {
    Asc = 0,
    Desc = 1,
}

export enum OrderSortingType {
    Status = 0,
    OrderNumber = 10,
    Date = 20,
    Employee = 30,
    Items = 40,
    Total = 50,
    MenuType = 60,
    Canteen = 70,
    Company = 100,
    Payment = 110,
    DeliveredDate = 120,
    DeliveryLocation = 130,
}

export enum PurchasesFilterType {
    Status = 0,
    Employee = 10,
    Company = 20,
    Payment = 30,
    MenuType = 70,
}

export enum KitchenAdminFilterType {
    Status = 0,
    Customer = 20,
    Payment = 30,
    MenuType = 40,
    DeliveryDate = 50,
}

export enum InventoryFilterType {
    Status = 0,
    Type = 10,
}

export enum InventorySortingType {
    Name = 0,
    Status = 10,
    Type = 20,
    Category = 30,
    Price = 40,
    Supplier = 50,
    CreatedDate = 60,
}

export interface PreparedFiltersType {
    type: PurchasesFilterType | InventoryFilterType | KitchenAdminFilterType;
    value: string[];
}

export interface GuestsLunchFormInfo {
    amount: number;
    orderReference?: string;
}

export enum PredefinedCategoriesEnum {
    Undefined = 0,
    Custom = 10,
    MeetingRoom = 20,
    Food = 30,
}

export interface CreateProductDto {
    title: string | null;
    price: number;
    taxPercent: number;
    externalID: string | null;
    stock: number | null;
    categoryId: string | null;
    predefinedCategory: PredefinedCategoriesEnum;
    productStatus: ProductStatus;
    canteenId: string | null;
    description: string | null;
    productId: string | null;
}

export interface CreateFoodProductDto extends CreateProductDto {
    // TODO after deploy to production frontend and backend parts of allergens type since we'll use components only
    allergens: string[];
    components: string[];
}

export interface ComponentType {
    id: string;
    name: string;
    type: number;
}

export interface AllergenType {
    id: string;
    name: string;
}

export interface UpdateProductDto extends CreateProductDto {
    id: string;
}

export interface ProductLunchData {
    id: string;
    name: string;
}

export enum GboMenuType {
    Lunch = 0,
    FoodForMeeting = 10,
    Kiosk = 20,
}

export interface GboMenuGroup {
    id: string;
    name: string;
}

export interface GboDiscount {
    id: number;
    name: string;
}

export interface GboMenu {
    id: number;
    name: string;
    menuGroups: GboMenuGroup[];
}

export interface GboSettings {
    type: GboMenuType;
    menuId: number;
    discountId: number;
    categoryIds: string[];
    canteenId: string | null;
    canteenName: string | null;
}

export interface GboFormSettings {
    menu: number;
    menuGroup: string[];
    discountRule: number;
    type: GboMenuType;
    canteenName: string | null;
    canteenId: string | null;
}

export type CompanySettings = {
    externalCompanyIds: ExternalIdsInCompany[];
    invoicingEnabled: boolean;
};
export type ExternalIdsInCompany = {
    pointOfSale: PointOfSaleName;
    externalCompanyId: string;
};

export interface ExternalCompanyPerPointOfSale {
    pointOfSale: PointOfSaleName;
    companies: ExternalCompany[];
}
export interface ExternalCompany {
    id: string;
    name: string;
}
export enum PointOfSaleName {
    Izi = 0,
    FoodTime = 10,
    DynamicWeb = 20,
    Munu = 30,
    Email = 40,
    GoldByOpen = 50,
}
export interface UserInvoiceSettings {
    lunchInvoiceEnabled: boolean;
    kioskInvoiceEnabled: boolean;
}

export type AccessEntitiesType = { buildings: string[]; companies: string[]; floors: string[] } | null;

export interface CanteenAvailability {
    access: AccessEntitiesType;
    allHaveAccess: boolean;
    canteenId: string;
    canteenName: string;
}
export interface CanteenWithAvailabilityParams {
    suggestedCanteenIds: string[];
    isDelivery: boolean;
    orderDateTime: Date | null;
    menuType: MenuType | null;
}

export interface DeliveryLocationOptionFilters extends BuildingsWithAvailableRoomsParams {
    date: Date;
}
