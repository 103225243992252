import { ReactElement, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroller from 'react-infinite-scroller';
import styled from 'styled-components';
import { OrderSortDirection, OrderSortingType } from '../../../../api/apiTypes/shopApiTypes';
import LoaderComponent from '../../../../common/Loader/LoaderComponent';
import {
    FilteringDrawer,
    GhostPrimaryButton,
    InformationMessage,
    PrimaryButton,
    SearchInput,
    SearchSelect,
    SortingDrawer,
    SvgIcon,
} from '../../../../components';
import { GroupedOptionType, OptionType } from '../../../../components/select/searchSelect/types';
import { useDebounce } from '../../../../utility';
import { useIsMobile } from '../../../../utility/hooks/useIsMobile';
import useResizeWindow from '../../../../utility/hooks/useResizeWindow';
import useScrollToElement from '../../../../utility/hooks/useScrollToElement';
import {
    useGetShopFilterStatusOptions,
    useGetShopFilterCompanyOptions,
    useGetShopFilterEmployeeOptions,
    useGetShopFilterPaymentOptions,
} from '../../apiQueries/useShopFilters';
import { useGetInfiniteCompanyPurchases } from '../../apiQueries/useShopOrders';
import { ShopAtWorkContext, ShopOverviewView } from '../../ShopAtWork';
import { Header, HorizontalRule } from '../../styles/ShopAtWorkStyles';
import ExtractPurchasesModal from './components/ExtractPurchasesModal';
import PurchasesTable from './components/PurchasesTable';
import {
    FilterKeys,
    getShopSettings,
    getViewFilters,
    preparePurchaseFilters,
    renderStatusOptions,
    renderPaymentOptions,
    renderEmployeeOptions,
    renderCompanyOptions,
    getFilterDropdownOptions,
    purchasesHeader,
} from './utils';

const MainContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

type SearchFieldProps = { isTabletOrMobileView: boolean };
const SearchField = styled(SearchInput)`
    min-width: ${({ isTabletOrMobileView }: SearchFieldProps) => (isTabletOrMobileView ? 'auto' : '21.071rem')};
    width: 100%;
    height: 2.857rem;
    margin: 0;
    flex: 1;

    input {
        text-overflow: ellipsis;
    }
`;

const PurchasesFilter = styled(SearchSelect)`
    min-width: 12rem;
`;

type ControlsWrapperProps = { isTabletOrMobileView: boolean };
const ControlsWrapper = styled.div`
    display: flex;
    flex-direction: ${({ isTabletOrMobileView }: ControlsWrapperProps) => (isTabletOrMobileView ? 'column' : 'row')};
    justify-content: space-between;
    align-items: ${({ isTabletOrMobileView }: ControlsWrapperProps) =>
        isTabletOrMobileView ? 'flex-start' : 'center'};
    flex-wrap: wrap;
    gap: 0.714rem;
`;

const SearchAndSortContainer = styled.div`
    min-width: auto;
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0.571rem;
    margin: 0.571rem 0;
`;

const MobileViewControlButton = styled(PrimaryButton)`
    height: 2.857rem;

    svg {
        rect {
            fill: var(--background-color);
        }
    }
`;

const StyledHorizontalRule = styled(HorizontalRule)`
    width: 100%;
    margin: 0;
`;

type PurchasesFiltersWrapperProps = { isTabletOrMobileView: boolean };
const PurchasesFiltersWrapper = styled.div`
    display: flex;
    flex-direction: ${({ isTabletOrMobileView }: PurchasesFiltersWrapperProps) =>
        isTabletOrMobileView ? 'column' : 'row'};
    gap: 0.571rem;
    justify-content: ${({ isTabletOrMobileView }: PurchasesFiltersWrapperProps) =>
        isTabletOrMobileView ? 'start' : 'end'};
`;

const LoaderWrapper = styled.div`
    height: 5rem;
`;

const FilterDrawerButtonContainer = styled.div`
    gap: 0.571rem;
    flex-wrap: wrap;
    width: 100%;
    display: flex;
`;

const OpenFilterDrawerButton = styled(GhostPrimaryButton)`
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;

    &&& {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        gap: 0.285rem;
    }
`;

const ExtractPurchasesButton = styled(PrimaryButton)`
    align-items: center;
    display: flex;
    gap: 0.571rem;
`;

const ButtonTextWrapper = styled.div`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

const ButtonCount = styled.p`
    display: inline;
`;

const ButtonText = styled.p`
    display: inline;
`;

interface PurchasesOverviewProps {
    view: ShopOverviewView;
}

//TODO move it
export interface PurchasesFilterProps {
    isSearchable: boolean;
    key: FilterKeys;
    title: string;
    options: OptionType[] | GroupedOptionType[] | [];
    onChange: (options: OptionType[] | []) => void;
    value: OptionType[] | [];
    onResetOptions: () => void;
    autoMenuPosition: boolean;
}

//TODO move it
const DESKTOP_BREAKPOINT = 870;

const PurchasesOverview = ({ view }: PurchasesOverviewProps): ReactElement => {
    const { state: ShopContextState, dispatch } = useContext(ShopAtWorkContext);
    const { sortingType, sortingDirection, ...filters } = getShopSettings(ShopContextState);
    const { data: statusFilterOptions } = useGetShopFilterStatusOptions();
    const { data: companiesFilterOptions } = useGetShopFilterCompanyOptions();
    const { data: employeeFilterOptions } = useGetShopFilterEmployeeOptions();
    const { data: paymentFilterOptions } = useGetShopFilterPaymentOptions();
    const containerWidth = useResizeWindow(ShopContextState.scrollRef);
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'portalPurchases' });
    const { t: tCommon } = useTranslation('common');

    const isTabletOrMobileView = containerWidth < DESKTOP_BREAKPOINT;
    const isMobile = useIsMobile();
    const debouncedSearchTerm = useDebounce(ShopContextState.searchTerm.trim().replace(/\s+/g, ' '), 200);
    const preparedFilters = preparePurchaseFilters(filters);
    const { statuses, payments, employees, companies } = filters;
    const [showMobileFilters, setShowMobileFilters] = useState(false);

    useScrollToElement(ShopContextState.fromOrderId.orderId?.toString());

    const { data, isLoading, isFetchingMore, isError, canFetchMore, fetchMore } = useGetInfiniteCompanyPurchases(
        sortingType,
        sortingDirection,
        debouncedSearchTerm,
        view,
        preparedFilters,
    );
    const [showExtractionModal, setShowExtractionModal] = useState(false);
    const [preselectedCompanies, setPreselectedCompanies] = useState<OptionType[]>([]);

    const orders = useMemo(() => (data ? data.map(({ data }) => data) : []).flat(), [data]);
    const ordersCount = useMemo(() => (data?.length ? data[data.length - 1].count : 0), [data]);
    const filterDropdownOptions = getFilterDropdownOptions(view, isMobile);

    const handleSort = (sortColumn: { path: OrderSortingType; order: OrderSortDirection }) => {
        dispatch({ type: 'UPDATE_SHOP_OVERVIEW_SORTING_TYPE', payload: { sortingType: sortColumn.path, view } });
        dispatch({
            type: 'UPDATE_SHOP_OVERVIEW_SORTING_DIRECTION',
            payload: { sortingDirection: sortColumn.order, view },
        });
    };

    const onChangeSearchTermHandler = (searchTerm: string) => {
        dispatch({
            type: 'UPDATE_SHOP_OVERVIEW_SEARCH_TERM',
            payload: searchTerm,
        });
    };

    const onChangeFiltersHandler = (filterType: string, selectedOptions: OptionType[]) => {
        dispatch({
            type: 'UPDATE_SHOP_OVERVIEW_FILTERS',
            payload: { [filterType]: selectedOptions, view },
        });
    };

    const onSortDirectionHandler = () => {
        sortingDirection === OrderSortDirection.Desc
            ? dispatch({
                  type: 'UPDATE_SHOP_OVERVIEW_SORTING_DIRECTION',
                  payload: { sortingDirection: OrderSortDirection.Asc, view },
              })
            : dispatch({
                  type: 'UPDATE_SHOP_OVERVIEW_SORTING_DIRECTION',
                  payload: { sortingDirection: OrderSortDirection.Desc, view },
              });
    };

    useEffect(() => {
        dispatch({ type: 'UPDATE_SHOP_OVERVIEW_VIEW', payload: view });
    }, [view, dispatch]);

    const handleFetchMore = () => {
        if (!isFetchingMore) fetchMore();
    };

    const [visibleDrawer, setDrawerVisibility] = useState<string | null>(null);

    const purchasesFilters: PurchasesFilterProps[] = useMemo(() => {
        const allFilters = [
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Statuses, options),
                value: statuses,
                key: FilterKeys.Statuses,
                title: tCommon('status'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Statuses, []),
                options: renderStatusOptions(statusFilterOptions),
                isSearchable: false,
                autoMenuPosition: true,
            },
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Payments, options),
                value: payments,
                key: FilterKeys.Payments,
                title: t('purchasesFilterPayments'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Payments, []),
                options: renderPaymentOptions(paymentFilterOptions),
                isSearchable: false,
                autoMenuPosition: true,
            },
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Employees, options),
                value: employees,
                key: FilterKeys.Employees,
                title: t('purchasesFilterEmployees'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Employees, []),
                options: renderEmployeeOptions(employeeFilterOptions),
                isSearchable: true,
                autoMenuPosition: true,
            },
            {
                onChange: (options: OptionType[] | []) => onChangeFiltersHandler(FilterKeys.Companies, options),
                value: companies,
                key: FilterKeys.Companies,
                title: t('companies'),
                onResetOptions: () => onChangeFiltersHandler(FilterKeys.Companies, []),
                options: renderCompanyOptions(companiesFilterOptions),
                isSearchable: true,
                autoMenuPosition: true,
            },
        ];

        return getViewFilters(view, allFilters);
    }, [filters, view]);

    return (
        <MainContainer>
            <Header>{purchasesHeader(view)}</Header>
            <ControlsWrapper isTabletOrMobileView={isTabletOrMobileView}>
                <SearchAndSortContainer>
                    <SearchField
                        isTabletOrMobileView={isTabletOrMobileView}
                        onChange={onChangeSearchTermHandler}
                        value={ShopContextState.searchTerm}
                        placeholder={t('searchPlaceholder')}
                    />
                    {isTabletOrMobileView && (
                        <MobileViewControlButton onClick={() => setDrawerVisibility('sorting')}>
                            <SvgIcon name="SortIcon" />
                        </MobileViewControlButton>
                    )}
                    {isTabletOrMobileView && (
                        <MobileViewControlButton onClick={() => setShowMobileFilters(!showMobileFilters)}>
                            <SvgIcon name="FilterIcon" />
                        </MobileViewControlButton>
                    )}

                    {isTabletOrMobileView && view === ShopOverviewView.PortalPurchases && (
                        <MobileViewControlButton onClick={() => setShowExtractionModal(true)}>
                            <SvgIcon name="DownloadIcon" />
                        </MobileViewControlButton>
                    )}
                </SearchAndSortContainer>

                {isTabletOrMobileView ? (
                    showMobileFilters ? (
                        <FilterDrawerButtonContainer>
                            {purchasesFilters.map((filter) => {
                                const isVisible = visibleDrawer === filter.title;
                                return (
                                    <>
                                        <OpenFilterDrawerButton
                                            key={filter.key}
                                            onClick={() => setDrawerVisibility(filter.title)}
                                        >
                                            <ButtonTextWrapper>
                                                <ButtonText>{filter.title} </ButtonText>
                                            </ButtonTextWrapper>

                                            {!!filter.value.length && (
                                                <ButtonCount> {`(${filter.value.length})`}</ButtonCount>
                                            )}
                                        </OpenFilterDrawerButton>
                                        <FilteringDrawer
                                            isVisible={isVisible}
                                            onClose={() => setDrawerVisibility(null)}
                                            onReset={filter.onResetOptions}
                                            filterName={filter.key}
                                            filterTitle={filter.title}
                                            filterOptions={filter.options}
                                            filterValue={filter.value}
                                            isSearchable={filter.isSearchable}
                                            onFilterChange={filter.onChange}
                                            itemsCount={ordersCount}
                                        ></FilteringDrawer>
                                    </>
                                );
                            })}
                        </FilterDrawerButtonContainer>
                    ) : null
                ) : (
                    <PurchasesFiltersWrapper isTabletOrMobileView={isTabletOrMobileView}>
                        {purchasesFilters.map(
                            ({
                                isSearchable,
                                value,
                                onChange,
                                onResetOptions,
                                key,
                                title,
                                options,
                                autoMenuPosition,
                            }) => (
                                <PurchasesFilter
                                    key={key}
                                    onChange={onChange}
                                    value={value}
                                    name={key}
                                    title={title}
                                    onResetOptions={onResetOptions}
                                    options={options}
                                    isSearchable={isSearchable}
                                    autoMenuPosition={autoMenuPosition}
                                />
                            ),
                        )}
                        {view === ShopOverviewView.PortalPurchases && (
                            <ExtractPurchasesButton onClick={() => setShowExtractionModal(true)}>
                                {t('exportExcelModalConfirmButton')}
                                <SvgIcon name="DownloadIcon" />
                            </ExtractPurchasesButton>
                        )}
                    </PurchasesFiltersWrapper>
                )}
                {showExtractionModal && (
                    <ExtractPurchasesModal
                        open={showExtractionModal}
                        onClose={() => setShowExtractionModal(false)}
                        preselectedCompanies={preselectedCompanies}
                        setPreselectedCompanies={(values: OptionType[]) => setPreselectedCompanies(values)}
                    />
                )}
                {isTabletOrMobileView && (
                    <>
                        <SortingDrawer
                            isVisible={visibleDrawer === 'sorting'}
                            onClose={() => setDrawerVisibility(null)}
                            onChange={(value: unknown) =>
                                dispatch({
                                    type: 'UPDATE_SHOP_OVERVIEW_SORTING_TYPE',
                                    payload: { sortingType: value as OrderSortingType, view },
                                })
                            }
                            filterOptions={filterDropdownOptions}
                            filterValue={sortingType}
                            sortingDirection={sortingDirection}
                            onChangeDirection={onSortDirectionHandler}
                        />
                        <StyledHorizontalRule />
                    </>
                )}
            </ControlsWrapper>
            {isError || !orders ? (
                <InformationMessage type="error">{t('portalPurchasesError')}</InformationMessage>
            ) : (
                <InfiniteScroller
                    threshold={200}
                    hasMore={canFetchMore}
                    loadMore={handleFetchMore}
                    loader={
                        <LoaderWrapper key={0}>
                            <LoaderComponent text={t('loaderText')} />
                        </LoaderWrapper>
                    }
                    useWindow={false}
                    getScrollParent={() => ShopContextState.scrollRef?.current ?? null}
                >
                    <PurchasesTable
                        orders={orders}
                        view={view}
                        isLoading={isLoading}
                        onSort={handleSort}
                        sortColumn={{
                            path: sortingType,
                            order: sortingDirection,
                        }}
                        collapse={isTabletOrMobileView}
                    />
                </InfiniteScroller>
            )}
        </MainContainer>
    );
};

export default PurchasesOverview;
