import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ContentConfigModel, ImageModel } from '../../../../../api/apiTypes/cmsApiTypes';
import ImageUploader from '../../../../../common/ImageUploader/ImageUploader';
import { TertiaryButton, SvgIcon } from '../../../../../components';
import { useUploadArticleImage } from '../../../Articles/ApiQueries/useImages';
import { useSetMenuImageInCache, useUpdateContentConfigFoodCoverImage } from '../../../usePortalContentConfig';
import UploadButton from './UploadButton';

const TextContainer = styled.div`
    font-size: 1.1rem;
    line-height: 1.71rem;
    text-align: left;
`;

const ImageContainer = styled.div`
    margin-top: 0.57rem;
`;

const Image = styled.img`
    width: 100%;
    border-top: 1px solid var(--surface-color-light);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    display: flex;
    flex-direction: column;
    height: 10.57rem;
    object-fit: cover;
    overflow: hidden;
`;

const DisplayImageContainer = styled.div`
    border: 1px solid var(--surface-color-light);
    border-radius: 0.3rem;
    background: var(--surface-color);
`;

const EditImageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid var(--border-color);
    border-bottom-left-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
`;
const FileName = styled.div`
    margin-left: 0.625rem;
    display: flex;
    align-items: center;
    font-size: 0.75rem;
`;
const RevertButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`;

const RevertButton = styled(TertiaryButton)`
    &&& {
        background: none;

        && path {
            fill: var(--primary-color);
        }

        :hover {
            color: var(--primary-color-light);
        }

        :focus,
        :focus-visible {
            outline-color: var(--primary-color);
        }
        :focus:not(:focus-visible) {
            outline: none;
        }
    }

    & > svg {
        margin-right: 0.57rem;
    }
`;

const VerticalSeparator = styled.div`
    height: inherit;
    border-right: 1px solid #d4d6d7;
`;

const ButtonText = styled.span`
    font-size: 1rem;
`;

interface MenuImageEditingProps {
    contentConfig: ContentConfigModel;
}

const MenuImageEditing = ({ contentConfig }: MenuImageEditingProps): ReactElement => {
    const [uploadImage, { isLoading: isUploadingImage }] = useUploadArticleImage();

    const [openImageUploader, setOpenImageUploader] = useState(false);
    const [updateContentConfig] = useUpdateContentConfigFoodCoverImage();
    const { t } = useTranslation('Dashboard');
    const setImageInCache = useSetMenuImageInCache();

    const handleImageUploadOnclick = () => {
        setOpenImageUploader(true);
    };

    const onUploadImageSuccessHandler = (image: ImageModel): void => {
        setImageInCache(image);
        setOpenImageUploader(false);
    };

    const onGoBackToDefaultImage = () => {
        updateContentConfig([null]);
    };

    const onGoBack = () => {
        setOpenImageUploader(true);
    };
    return (
        <>
            <TextContainer>{t('dailyMenu.menuImageEditingText')}</TextContainer>
            <ImageContainer>
                {contentConfig.isDefaultFoodMenuCoverImage || !contentConfig.foodMenuCoverImage ? (
                    <UploadButton onClick={handleImageUploadOnclick} />
                ) : (
                    <>
                        <DisplayImageContainer>
                            <Image src={contentConfig.foodMenuCoverImage.url} alt="" />
                        </DisplayImageContainer>
                        <EditImageContainer>
                            <FileName>{contentConfig.foodMenuCoverImage.name}</FileName>
                            <RevertButtonContainer>
                                <RevertButton onClick={onGoBackToDefaultImage}>
                                    <ButtonText>{t('dailyMenu.useDefaultImage')}</ButtonText>
                                </RevertButton>
                                <VerticalSeparator></VerticalSeparator>
                                <RevertButton onClick={onGoBack}>
                                    <SvgIcon name="UndoIcon" />
                                    <ButtonText>{t('canteenPass.edit')}</ButtonText>
                                </RevertButton>
                            </RevertButtonContainer>
                        </EditImageContainer>
                    </>
                )}
                {openImageUploader ? (
                    <ImageUploader
                        onClose={() => setOpenImageUploader(false)}
                        onUploadImageSuccess={onUploadImageSuccessHandler}
                        uploadImage={uploadImage}
                        isUploadingImage={isUploadingImage}
                        refProp="portal-content-config"
                        refId={`${contentConfig?.id ?? ''}`}
                        field="foodMenuCoverImage"
                    />
                ) : null}
            </ImageContainer>
        </>
    );
};

export default MenuImageEditing;
