import { ReactElement, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import * as yup from 'yup';
import { Loader } from '../../../../../common';
import {
    DateInput,
    ErrorDisplay,
    FormLabel,
    GhostPrimaryButton,
    Modal,
    ModalType,
    PrimaryButton,
    SuccessDisplay,
    SvgIcon,
} from '../../../../../components';
import { OptionType } from '../../../../../components/select/searchSelect/types';
import StyledSelect from '../../../../../components/select/StyledSelect';
import { useGetShopFilterCompanyOptions } from '../../../apiQueries/useShopFilters';
import { useGetOrdersByPeriodExcelFile } from '../../../apiQueries/useShopOrders';

type ContainerProps = { noDataError: boolean };

const Container = styled.div`
    padding: 1.714rem;
    padding-bottom: ${({ noDataError }: ContainerProps) => (noDataError ? '3.285rem' : '10.285rem')};
`;

const ModalDescription = styled.p``;

const GroupedContainer = styled.div`
    display: flex;
    gap: 0.571rem;
`;

const FormElementContainer = styled.div`
    width: 100%;
`;

const StatusContainer = styled.div`
    text-align: center;
`;

const StyledDateInput = styled(DateInput)`
    padding: 0.6785rem 1rem;
`;

const ButtonsWrapper = styled.div`
    display: flex;
    gap: 1.142rem;
    justify-content: flex-end;
`;

const ControllerWrapper = styled.div`
    margin-top: 1.714rem;
`;

const LoaderContainer = styled.div`
    padding: 10.285rem;
`;

const NoDataContainer = styled.div`
    margin-top: 1.142rem;
    border-radius: 5px;
    background-color: #fff6c9;

    padding: 1.142rem 0.571rem;
    display: flex;
    gap: 0.571rem;
`;

const SvgContainer = styled.div`
    width: 1.428rem;
    svg {
        & path {
            fill: #d2b100;
        }
    }
`;

const NoDataText = styled.p`
    color: #263238;
    font-size: 1rem;
    line-height: 1.714rem;
`;

const NoDataCrossButton = styled.button`
    background-color: transparent;
    border: none;
    padding: 0;
    display: flex;
    cursor: pointer;

    svg {
        height: 0.714rem;
        path {
            fill: #475156;
        }
    }
`;

const schema = yup.object().shape({
    fromDate: yup.string(),
    toDate: yup.string(),
    companies: yup.array().required().min(1, 'asd'),
});

type Form = {
    fromDate: string;
    toDate: string;
    products: OptionType[] | OptionType;
    companies: OptionType[] | OptionType;
};

interface ExtractPurchasesModalProps {
    open: boolean;
    preselectedCompanies: OptionType[];
    setPreselectedCompanies(values: OptionType[]): void;
    onClose(): void;
}
const ExtractPurchasesModal = ({
    open,
    preselectedCompanies,
    setPreselectedCompanies,
    onClose,
}: ExtractPurchasesModalProps): ReactElement => {
    const defaultDate = new Date();
    const [noDataError, showNoDataError] = useState(false);
    const { t } = useTranslation('ShopAtWork', { keyPrefix: 'portalPurchases' });
    const { t: tCommon } = useTranslation('common');

    // TODO remove when backend part is ready
    const productOptions = [{ label: 'all', value: 'all' }];

    const [downloadExcelFile, { isLoading: isDownloadingInProgress, error, isError, isSuccess, reset }] =
        useGetOrdersByPeriodExcelFile();

    const { data: companyOptions, isLoading: isCompanyOptionsLoading } = useGetShopFilterCompanyOptions();

    const modifiedCompanyOptions = useMemo(() => {
        return companyOptions ? companyOptions.map((option) => ({ label: option.name, value: option.id })) : [];
    }, [companyOptions]);

    const {
        handleSubmit,
        control,
        getValues,
        setValue,
        watch,
        formState: { isValid },
    } = useForm<Form>({
        mode: 'onTouched',
        resolver: yupResolver(schema),
        defaultValues: {
            fromDate: new Date(defaultDate.setMonth(defaultDate.getMonth() - 1)).toISOString(),
            products: productOptions,
            companies: preselectedCompanies[0] ? preselectedCompanies : modifiedCompanyOptions,
            toDate: new Date().toISOString(),
        },
    });

    useEffect(() => {
        isError && error?.response?.status === 404 && showNoDataError(true);
    }, [isError]);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const handleDownloadClick = (values: Form) => {
        const { fromDate, toDate, companies } = values;
        downloadExcelFile([fromDate, toDate, (companies as OptionType[]).map((item) => item.value), timezone]);
    };

    const modalTitle =
        isError && !noDataError
            ? t('exportExcelModalFailTitle')
            : isSuccess
            ? t('exportExcelModalSuccessTitle')
            : t('exportExcelModalTitle');

    const getContent = () => {
        if (isSuccess) {
            return (
                <StatusContainer>
                    <SuccessDisplay header={t('exportExcelModalSuccessMessage')} alt="success" />
                </StatusContainer>
            );
        } else if (isError && !noDataError) {
            return (
                <StatusContainer>
                    <ErrorDisplay header={t('exportExcelModalFailMessage')} />
                </StatusContainer>
            );
        } else {
            return (
                <form>
                    <ModalDescription>{t('exportExcelModalDescription')}</ModalDescription>
                    <GroupedContainer>
                        <FormElementContainer>
                            <FormLabel>{tCommon('fromDate')}</FormLabel>
                            <Controller
                                render={({ field: { value } }) => {
                                    const handleSetDate = (date: Date) =>
                                        setValue('fromDate', date.toISOString(), {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                        });
                                    return (
                                        <StyledDateInput
                                            name="fromDate"
                                            dateformat="d.MM.yyyy"
                                            value={value ? new Date(value) : null}
                                            onChange={handleSetDate}
                                            icon={{ name: 'calendar alternate' }}
                                            parentElementId="fullWindowDiv"
                                            maxDate={watch('toDate') ? new Date(watch('toDate') as string) : null}
                                        />
                                    );
                                }}
                                name="fromDate"
                                control={control}
                            />
                        </FormElementContainer>
                        <FormElementContainer>
                            <FormLabel>{tCommon('toDate')}</FormLabel>
                            <Controller
                                render={({ field: { value } }) => {
                                    const handleSetDate = (date: Date) =>
                                        setValue('toDate', date.toISOString(), {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                        });
                                    return (
                                        <StyledDateInput
                                            name="toDate"
                                            dateformat="d.MM.yyyy"
                                            value={value ? new Date(value) : null}
                                            onChange={handleSetDate}
                                            icon={{ name: 'calendar alternate' }}
                                            parentElementId="fullWindowDiv"
                                            minDate={watch('fromDate') ? new Date(watch('fromDate') as string) : null}
                                            maxDate={new Date()}
                                        />
                                    );
                                }}
                                name="toDate"
                                control={control}
                            />
                        </FormElementContainer>
                    </GroupedContainer>
                    <ControllerWrapper>
                        <Controller
                            name="products"
                            control={control}
                            render={({ field }) => {
                                const handleSetValue = (value: OptionType | OptionType[]) =>
                                    setValue('products', value, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                return (
                                    //TODO - This select input is disabled until we have the opportunity to change the product type
                                    <StyledSelect
                                        label={t('exportExcelModalProductsDropdownLabel')}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={productOptions}
                                        {...field}
                                        onChange={handleSetValue}
                                        value={getValues().products}
                                        isCommonSelect
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue
                                        backspaceRemovesValue={false}
                                        disabled
                                        allSelectable
                                        menuPositionIsFixed={true}
                                    />
                                );
                            }}
                        ></Controller>
                    </ControllerWrapper>
                    <ControllerWrapper>
                        <Controller
                            name="companies"
                            control={control}
                            render={({ field }) => {
                                const handleSetValue = (value: OptionType | OptionType[]) => {
                                    setPreselectedCompanies(value as OptionType[]);
                                    setValue('companies', value, {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    });
                                };
                                return (
                                    <StyledSelect
                                        label={t('companies')}
                                        closeMenuOnSelect={false}
                                        isMulti
                                        options={modifiedCompanyOptions}
                                        {...field}
                                        onChange={handleSetValue}
                                        value={getValues().companies}
                                        isCommonSelect
                                        hideSelectedOptions={false}
                                        controlShouldRenderValue
                                        backspaceRemovesValue={false}
                                        disabled={false}
                                        allSelectable
                                        height={'10.2rem'}
                                    />
                                );
                            }}
                        ></Controller>
                    </ControllerWrapper>
                    {noDataError && (
                        <NoDataContainer>
                            <SvgContainer>
                                <SvgIcon name="InfoCircleOutline" />
                            </SvgContainer>
                            <NoDataText>{t('exportExcelModalNoDataMessage')}</NoDataText>
                            <NoDataCrossButton type="button" onClick={() => showNoDataError(false)}>
                                <SvgIcon name="CloseIcon" />
                            </NoDataCrossButton>
                        </NoDataContainer>
                    )}
                </form>
            );
        }
    };

    const getBottomContent = (): ReactElement => {
        if (isCompanyOptionsLoading || isDownloadingInProgress) {
            return <></>;
        }
        if (isSuccess) {
            return (
                <ButtonsWrapper>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('closeButton')} </GhostPrimaryButton>
                </ButtonsWrapper>
            );
        }
        if (isError && !noDataError) {
            return (
                <ButtonsWrapper>
                    <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                    <PrimaryButton onClick={() => reset()}>{t('exportExcelModalTryAgainButton')}</PrimaryButton>
                </ButtonsWrapper>
            );
        }
        return (
            <ButtonsWrapper>
                <GhostPrimaryButton onClick={onClose}>{tCommon('cancelButton')}</GhostPrimaryButton>
                <PrimaryButton onClick={handleSubmit(handleDownloadClick)} disabled={!isValid}>
                    {t('exportExcelButton')}
                </PrimaryButton>
            </ButtonsWrapper>
        );
    };

    return (
        <Modal
            title={modalTitle}
            type={ModalType.Small}
            open={open}
            onClose={onClose}
            bottomContent={getBottomContent()}
        >
            {isCompanyOptionsLoading || isDownloadingInProgress ? (
                <LoaderContainer>
                    <Loader />
                </LoaderContainer>
            ) : (
                <Container noDataError={noDataError}>{getContent()}</Container>
            )}
        </Modal>
    );
};
export default ExtractPurchasesModal;
