import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { CategoryModel, SortingDateType, SubCategoryModel } from '../../api/apiTypes/cmsApiTypes';
import { sidebarOperations } from '../../common/Sidebar/duck';
import { useNavigation } from '../../utility';
import { useIsMobile } from '../../utility/hooks/useIsMobile';
import { ArticleStatusFilterOptions } from '../Dashboard/Articles/ApiQueries/useArticles';
import { filterHistoryState } from '../Dashboard/Articles/ArticlesOverview/ArticlesOverview';

interface CategoryFilter {
    category: CategoryModel['id'] | null;
    subcategory: SubCategoryModel['id'] | null;
}
type FilterType = {
    searchTerm?: string;
    sortBy?: SortingDateType.New | SortingDateType.Old;
    dateFilter?: { fromDate: Date | undefined; toDate: Date | undefined };
    typeFilter?: ArticleStatusFilterOptions;
    categoryFilter?: CategoryFilter;
};

interface UseArticlesNavigationReturnType {
    goToArticle(articleId: string): void;
    goToNewArticle(): void;
    goToArticleOverview(state?: filterHistoryState): void;
    goToCategories(): void;
    editArticle(articleId: string): void;
    getArticleLink(articleId: string): string;
    articleOverviewLink: string;
    articlesLink: string;
    goToSelectedArticleCategory(articleSelectedCategoryLink?: number): void;
    goToSelectedArticleSubCategory(
        articleSelectedCategoryLink?: number,
        articleSelectedSubCategoryLink?: number | null,
    ): void;
    pathname: string;
    setCategoryFilter(filter: CategoryFilter): void;
    setTypeFilter(type: FilterType['typeFilter']): void;
    setSearchTerm(term: string): void;
    setSortBy(sortBy: FilterType['sortBy']): void;
    setDateFilter(dateFilter: FilterType['dateFilter']): void;
    categoryId: number | null;
    subCategoryId: number | null;
}

const useArticlesNavigation = (): UseArticlesNavigationReturnType => {
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const history = useHistory<filterHistoryState>();
    const { categoryId, subCategoryId } = useParams<{ categoryId?: string; subCategoryId?: string }>();
    const { pathname } = history.location;

    const articlesLink = `/dashboard/articles`;
    const getArticlesLink = (categoryId: number | string) => `${articlesLink}/category/${categoryId}`;
    const getArticlesSubCategoryLink = (categoryId: number | string, subcategoryId: number | null) =>
        `${articlesLink}/category/${categoryId}/subcategory/${subcategoryId}`;
    const getArticleLink = (articleId: string) => `${articlesLink}/${articleId}`;
    const newArticleLink = `${articlesLink}/new`;
    const categoriesLink = `${articlesLink}/categories`;
    const articleOverviewLink = `${articlesLink}/category`;
    const getEditArticleLink = (articleId: string) => `${articlesLink}/${articleId}/edit`;
    const onNavigate = (url: string, shouldCloseSideBar = true) => {
        if (isMobile && shouldCloseSideBar) dispatch(sidebarOperations.handleHideSidebar());
        navigation.push(url);
    };

    const mergeHistoryState = <KeyType extends keyof FilterType>(key: KeyType, value: FilterType[KeyType]) => {
        const stateCopy = { ...(history.location.state?.filters ?? {}) };
        stateCopy[key] = value;
        history.replace({
            state: { filters: stateCopy },
        });
    };

    const setCategoryFilter = (filter: CategoryFilter) => mergeHistoryState('categoryFilter', filter);
    const setSearchTerm = (term: string) => mergeHistoryState('searchTerm', term);
    const setDateFilter = (dateFilter: FilterType['dateFilter']) => mergeHistoryState('dateFilter', dateFilter);
    const setTypeFilter = (type: FilterType['typeFilter']) => mergeHistoryState('typeFilter', type);
    const setSortBy = (sortBy: FilterType['sortBy']) => mergeHistoryState('sortBy', sortBy);

    return {
        pathname,
        getArticleLink,
        articlesLink,
        articleOverviewLink,
        goToSelectedArticleCategory: (categoryId?: number) => {
            return navigation.push(getArticlesLink(categoryId ?? ''), { categoryId });
        },
        goToSelectedArticleSubCategory: (categoryId?: number, subCategoryId?: number) => {
            if (!subCategoryId) {
                return navigation.push(getArticlesLink(categoryId ?? ''), { categoryId });
            }
            return navigation.push(getArticlesSubCategoryLink(categoryId ?? '', subCategoryId ?? null), { categoryId });
        },
        goToArticleOverview: () => onNavigate(articleOverviewLink, false),
        goToArticle: (articleId: string) => navigation.push(getArticleLink(articleId)),
        goToNewArticle: () => navigation.push(newArticleLink),
        editArticle: (articleId: string) => navigation.push(getEditArticleLink(articleId)),
        goToCategories: () => navigation.push(categoriesLink),
        setCategoryFilter,
        setSearchTerm,
        setDateFilter,
        setTypeFilter,
        setSortBy,
        categoryId: Number(categoryId),
        subCategoryId: Number(subCategoryId),
    };
};

export default useArticlesNavigation;
