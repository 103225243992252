import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { AssetSearchModel } from '../../../../api/apiTypes/assetApiTypes';
import {
    CompanyBasicInfo,
    CompanyContactPerson,
    CompanySearchModel,
    CreateNewTenantDTO,
    TenancyInfo,
    UpdateCompanyBasicInfo,
    UpdateTenancyInfo,
} from '../../../../api/apiTypes/portalApiTypes';
import { getAssetSearchInfo } from '../../../../api/assetApi';
import {
    createNewCompany,
    createNewCompanyV2,
    getCompanyBasicInfo,
    getCompanyContactPerson,
    getCompanySearchInfo,
    getCompanyTenancyInfo,
    updateCompanyBasicInfo,
    updateCompanyTenancyInfo,
} from '../../../../api/portalApi';

export const CompanyBasicInfoCacheKey = 'CompanyBasicInfoCacheKey';
const CompanyContactPersonCacheKey = 'CompanyContactPersonCacheKey';
const CompanyTenancyCacheKey = 'CompanyTenancyCacheKey';
const TenancyAssetsCacheKey = 'TenancyAssetsCacheKey';
export const CompaniesCacheKey = 'CompaniesCacheKey';

export const useGetCompanyBasicInfo = (
    companyId: string,
): QueryResult<CompanyBasicInfo, string | AxiosError<string> | Error> => {
    return useQuery([CompanyBasicInfoCacheKey, companyId], async () => {
        const result = await getCompanyBasicInfo(companyId);
        return result.data;
    });
};

export const useGetCompanyContactPerson = (
    companyId: string,
): QueryResult<CompanyContactPerson, string | AxiosError<string> | Error> => {
    return useQuery([CompanyContactPersonCacheKey, companyId], async () => {
        const result = await getCompanyContactPerson(companyId);
        return result.data;
    });
};

export const useGetCompanyTenancyInfo = (
    companyId: string,
): QueryResult<TenancyInfo, string | AxiosError<string> | Error> => {
    return useQuery([CompanyTenancyCacheKey, companyId], async () => {
        const result = await getCompanyTenancyInfo(companyId);
        return result.data;
    });
};

export const useGetAssets = (): QueryResult<AssetSearchModel[], string | AxiosError<string> | Error> => {
    return useQuery(
        [TenancyAssetsCacheKey],
        async () => {
            const result = await getAssetSearchInfo();
            return result.data;
        },
        {
            staleTime: Infinity,
        },
    );
};

export const useGetCompanies = (): QueryResult<CompanySearchModel[], string | AxiosError<string> | Error> => {
    return useQuery([CompaniesCacheKey], async () => {
        const result = await getCompanySearchInfo();
        return result.data;
    });
};

export const useUpdateCompanyTenancyInfo = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    [string, UpdateTenancyInfo],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([companyGuid, tenancyInfo]) => {
            return updateCompanyTenancyInfo(companyGuid, tenancyInfo);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(CompaniesCacheKey);
            },
        },
    );
};

export const useUpdateCompanyBasicInfo = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    [string, UpdateCompanyBasicInfo],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([companyGuid, basicInfo]) => {
            return updateCompanyBasicInfo(companyGuid, basicInfo);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(CompaniesCacheKey);
            },
        },
    );
};

export const useCreateNewCompany = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    [UpdateCompanyBasicInfo],
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        ([company]) => {
            return createNewCompany(company);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(CompaniesCacheKey);
            },
        },
    );
};

export const useCreateNewCompanyV2 = (): MutationResultPair<
    AxiosResponse<void>,
    string | AxiosError<string> | Error,
    CreateNewTenantDTO,
    never
> => {
    const cache = useQueryCache();
    return useMutation(
        (company) => {
            return createNewCompanyV2(company);
        },
        {
            onSuccess: () => {
                cache.invalidateQueries(CompaniesCacheKey);
            },
        },
    );
};
