import { AxiosResponse, AxiosError } from 'axios';
import { useMutation, useQueryCache, useInfiniteQuery, useQuery } from 'react-query';
import type { QueryCache, InfiniteQueryResult, QueryResult, MutationResultPair } from 'react-query';
import { useSelector } from 'react-redux';
import { IRootStateProps } from '../../../../../reducers';
import {
    ArticleDto,
    ArticleModel,
    CountUsersByAccessAndRolesDto,
    SortingDateType,
} from '../../../../api/apiTypes/cmsApiTypes';
import { RolePhrase } from '../../../../api/apiTypes/portalApiTypes';
import {
    createArticle,
    updateArticle,
    getArticles,
    getArticle,
    deleteArticle,
    getAllRoles,
} from '../../../../api/cmsApi';
import { countUsersByAccessEntitiesAndRoles } from '../../../../api/portalApi';
import useCurrentUserContext from '../../../../common/Contexts/useCurrentUserContext';
import { invalidateCategoryCount } from './useCategories';

const ArticleKey = 'Articles';
const SingleArticleKey = 'Article';
const UsersCountByAccessEntitiesAndRoles = 'UsersCountByAccessEntitiesAndRoles';

const mapArticle = (article: ArticleModel, askedForDraft: boolean): ArticleModel => ({
    ...article,
    isPublishedVersion: !askedForDraft ? article.isPublishedVersion : true,
    updated: new Date(article.updated),
    publishedAt: article.publishedAt ? new Date(article.publishedAt) : null,
    archivedAt: article.archivedAt ? new Date(article.archivedAt) : null,
    isFeatured: article.isFeatured ?? false,
});

const butChangedStatusPostfix = 'ButChanged';

export const useGetArticle = (
    articleId: string | undefined,
    draft = false,
    enabled = true,
): QueryResult<ArticleModel | undefined, Error | AxiosError<string>> => {
    const queryCache = useQueryCache();
    return useQuery<ArticleModel | undefined, Error | AxiosError<string>>(
        [SingleArticleKey, articleId, draft],
        async () => {
            if (!articleId) return undefined;
            const response = await getArticle(articleId, draft);
            const data = response.data;
            return mapArticle(data, draft);
        },
        {
            cacheTime: 1000 * 20,
            staleTime: Infinity,
            enabled: Boolean(articleId) && enabled,
            initialData: () => {
                const queryData = queryCache.getQueries<ArticleModel[][]>([ArticleKey]);
                const foundArticle = (
                    queryData
                        ?.filter((query) => !query.isStale() && !query.state.isInvalidated)
                        ?.map((articleList) => {
                            return articleList?.state?.data?.flat()?.find((article) => `${article?.id}` === articleId);
                        })
                        ?.filter(Boolean) as ArticleModel[]
                )
                    ?.sort((a, b) => new Date(a.updated).valueOf() - new Date(b.updated).valueOf())
                    ?.find((article) => {
                        return !article.status.endsWith(butChangedStatusPostfix);
                    });
                let foundSingleArticle;
                if (!draft) {
                    const article = queryCache.getQueryData<ArticleModel>([SingleArticleKey, articleId, true]);
                    if (article && !article?.status.endsWith(butChangedStatusPostfix)) {
                        foundSingleArticle = article;
                    }
                } else {
                    const article = queryCache.getQueryData<ArticleModel>([SingleArticleKey, articleId, false]);
                    if (article && !article?.status.endsWith(butChangedStatusPostfix)) {
                        foundSingleArticle = article;
                    }
                }
                return foundArticle
                    ? foundSingleArticle && new Date(foundSingleArticle.updated) > new Date(foundArticle.updated)
                        ? foundSingleArticle
                        : foundArticle
                    : undefined;
            },
        },
    );
};

export type ArticleStatusFilterOptions = 'published' | 'draft' | 'planned' | 'archived' | 'all';
export const useGetInfiniteArticles = (
    drafts: boolean,
    prioritizeFeatured = false,
    numberOfArticles?: number,
    pinnedCategoriesIds?: number[],
    searchTerm = '',
    sortBy: SortingDateType.New | SortingDateType.Old = SortingDateType.New,
    dateSearch?: { fromDate: Date | undefined; toDate: Date | undefined },
    status: ArticleStatusFilterOptions = 'published',
    category?: number,
    subcategory?: number,
): InfiniteQueryResult<ArticleModel[], Error | AxiosError<string>> => {
    const roles = useSelector((state: IRootStateProps) => state.app.roles);
    return useInfiniteQuery<ArticleModel[], Error | AxiosError<string>>(
        [
            ArticleKey,
            drafts,
            prioritizeFeatured,
            searchTerm,
            sortBy,
            dateSearch,
            status,
            category,
            subcategory,
            pinnedCategoriesIds,
        ],
        async (
            _,
            drafts,
            prioritizeFeatured,
            searchTerm,
            sortBy,
            dateSearch,
            status,
            category,
            subcategory,
            pinnedCategoriesIds,
            skip,
        ): Promise<ArticleModel[]> => {
            const articleStatusFilter = ['published', 'draft', 'archived', 'planned'].includes(status) ? status : null;
            const response = await getArticles(
                drafts,
                prioritizeFeatured,
                numberOfArticles,
                roles,
                skip,
                searchTerm,
                sortBy,
                dateSearch,
                articleStatusFilter,
                category,
                subcategory,
            );
            const data = response.data;
            return data.map((arg) => mapArticle(arg, drafts));
        },
        {
            getFetchMore: (lastPage, allPages) => {
                if (lastPage.length !== numberOfArticles) {
                    return false;
                }
                return lastPage.length * allPages.length;
            },
            cacheTime: Infinity,
            staleTime: 1000 * 60,
        },
    );
};

const getLastUpdated = (articleId: string, cache: QueryCache) => {
    const lastUpdated =
        cache.getQueryData<ArticleModel>([SingleArticleKey, articleId, true])?.updated ||
        cache.getQueryData<ArticleModel>([SingleArticleKey, articleId, false])?.updated;
    if (!lastUpdated) throw new Error('LastUpdated timestamp must be provided, when editing existing article');
    return lastUpdated;
};

export type SaveArticleDto = Omit<ArticleDto, 'author'>;

export const useSaveArticle = (): MutationResultPair<
    AxiosResponse<ArticleModel>,
    Error | AxiosError<string | { updatedBy: { firstName: string; lastName: string; avatar: string } }>,
    [string | null, SaveArticleDto, boolean],
    never
> => {
    const cache = useQueryCache();
    const { state: currentUserState } = useCurrentUserContext();

    return useMutation<
        AxiosResponse<ArticleModel>,
        Error | AxiosError<string>,
        [string | null, SaveArticleDto, boolean],
        never
    >(
        async ([articleId, article, draft]: [string | null, SaveArticleDto, boolean]) => {
            const articleWithAuthor = {
                ...article,
                author: {
                    firstName: currentUserState.currentUser?.firstName,
                    lastName: currentUserState.currentUser?.lastName,
                    avatar: currentUserState.currentUser?.avatar,
                },
            };
            if (articleId) {
                return await updateArticle(articleId, articleWithAuthor, getLastUpdated(articleId, cache), draft);
            } else {
                return await createArticle(articleWithAuthor, draft);
            }
        },
        {
            onSuccess: (response) => {
                invalidateCategoryCount(cache);
                cache.invalidateQueries(ArticleKey);
                const articleId = response.data.id;
                if (response.data.isPublishedVersion || !response.data.hasPublishedVersion) {
                    cache.setQueryData([SingleArticleKey, `${articleId}`, false], mapArticle(response.data, false));
                }

                cache.setQueryData([SingleArticleKey, `${articleId}`, true], mapArticle(response.data, true));
            },
        },
    );
};

export const useDeleteArticle = (): MutationResultPair<
    AxiosResponse<ArticleModel>,
    Error | AxiosError<string>,
    [string, boolean],
    never
> => {
    const cache = useQueryCache();
    return useMutation<AxiosResponse<ArticleModel>, Error | AxiosError<string>, [string, boolean], never>(
        async ([articleId, draft]) => {
            return await deleteArticle(articleId, draft, getLastUpdated(articleId, cache));
        },
        {
            onSuccess: (response, [articleId, draft]) => {
                invalidateCategoryCount(cache);
                if (draft) {
                    cache.setQueryData([SingleArticleKey, articleId, true], mapArticle(response.data, true));
                } else {
                    cache.getQueries([SingleArticleKey, articleId]).forEach((query) => query.setData(undefined));
                }
                cache.invalidateQueries(ArticleKey);
            },
        },
    );
};

export const useReloadArticles = (): (() => void) => {
    const cache = useQueryCache();

    return () => {
        const activeQueries = [
            ...cache.getQueries([ArticleKey]).filter((query) => query.isActive),
            ...cache.getQueries([SingleArticleKey]).filter((query) => query.isActive),
        ];
        setTimeout(
            () =>
                activeQueries.forEach((query) => {
                    query.setData(undefined);
                    query.refetch();
                }),
            10,
        );
    };
};

export const useGetRoles = (): QueryResult<RolePhrase[], AxiosError<string> | Error> => {
    return useQuery('roles', async () => {
        const result = await getAllRoles();
        return result.data;
    });
};

export const useGetUsersCountByAccessEntitiesAndRoles = (
    dto: CountUsersByAccessAndRolesDto,
): QueryResult<number, AxiosError<string> | Error> => {
    return useQuery(
        [UsersCountByAccessEntitiesAndRoles, JSON.stringify(dto)],
        async (): Promise<number> => {
            const result = await countUsersByAccessEntitiesAndRoles(dto);
            return result.data;
        },
        {
            enabled: false,
        },
    );
};
