import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const AddButton = styled.div`
    height: 40px;
    width: 40px;
    border: none;
    border-radius: 50%;

    background-color: var(--primary-color);
`;

const Button = styled.button`
    display: flex;
    border: none;
    background: none;
`;

const AddIcon = styled.p`
    color: var(--primary-on-color);
    font-size: 2em;
    margin: 0 !important;
`;

const TextSpan = styled.div`
    margin-left: 1em;
    text-align: left;
    & p {
        margin: 0;
        color: var(--text-medium-emphasis-color);
    }
    & p:first-child {
        font-weight: bold;
        color: var(--primary-color);
    }
`;

interface UploadButtonProps {
    onClick(): void;
}
const UploadButton = ({ onClick }: UploadButtonProps): ReactElement => {
    const { t } = useTranslation('Dashboard', { keyPrefix: 'dailyMenu' });
    return (
        <Button onClick={onClick}>
            <AddButton>
                <AddIcon>+</AddIcon>
            </AddButton>

            <TextSpan>
                <p>{t('uploadPicture')}</p>
                <p>{t('maxFileSize')}</p>
            </TextSpan>
        </Button>
    );
};

export default UploadButton;
