import type { TFunction } from 'i18next';
import { AllergenType } from '../../api/apiTypes/shopApiTypes';
import logger from '../../api/infrastructure/logService';

export const useGetAllergenTranslation = (allergen: string, t: TFunction): string | null => {
    switch (allergen) {
        case 'Celery':
            return t('celery');
        case 'Egg':
            return t('egg');
        case 'Fish':
            return t('fish');
        case 'Gluten':
            return t('gluten');
        case 'Lupine':
            return t('lupine');
        case 'Milk':
            return t('milk');
        case 'Molluscs':
            return t('molluscs');
        case 'Mustard':
            return t('mustard');
        case 'Nuts':
            return t('nuts');
        case 'Peanuts':
            return t('peanuts');
        case 'Sesame':
            return t('sesame');
        case 'Shellfish':
            return t('shellfish');
        case 'Soya':
            return t('soya');
        case 'Sulfite':
            return t('sulfite');

        default:
            logger.log(new Error(`Unknown allergen: ${allergen}`));
            return null;
    }
};

export const useGetTranslatedAllergens = (allergens: AllergenType[], tFunction: TFunction): AllergenType[] =>
    allergens
        .map(({ id, name }) => ({
            id,
            name: useGetAllergenTranslation(name, tFunction) || name,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));

export const useGetSortedAllergens = (allergens: AllergenType[]): AllergenType[] =>
    allergens.sort((a, b) => a.name.localeCompare(b.name));
