import { AxiosError } from 'axios';
import type { QueryResult } from 'react-query';
import { useQuery, useQueryCache } from 'react-query';
import { useParams } from 'react-router-dom';
import { queryCache } from '../../../index';
import { PortalConfig } from '../../api/apiTypes/configApiTypes';
import { getPortalConfig } from '../../api/configApi';

const portalConfigKey = 'portalConfig';
const getLocalStorageKey = (portalName: string) => `data-${portalConfigKey}-${portalName}`;

export const useGetPortalConfig = (portalName: string): QueryResult<PortalConfig, AxiosError<string> | Error> => {
    return useQuery([portalConfigKey, portalName], () => getPortalConfig(portalName).then((result) => result.data), {
        initialData: () => {
            const data = window.localStorage.getItem(getLocalStorageKey(portalName));
            if (data) {
                return JSON.parse(data);
            }
        },
        initialStale: true,
        staleTime: Infinity,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        onSuccess: (data) => {
            window.localStorage.setItem(getLocalStorageKey(portalName), JSON.stringify(data));
        },
    });
};

export const usePortalConfig = (): PortalConfig => {
    const { portalName } = useParams<{ portalName: string }>();
    const queryCache = useQueryCache();
    const config = queryCache.getQueryData<PortalConfig>([portalConfigKey, portalName]);
    if (!config) throw new Error('Unexpected, could not find portalconfig in queryCache');
    return config;
};

export const getPortalIdStatic = (): number => {
    const [, portalName] = decodeURI(window.location.pathname).split('/');
    const config = queryCache.getQueryData<PortalConfig>([portalConfigKey, portalName]);
    if (!config) throw new Error('Unexpected, could not find portalconfig in queryCache');
    return config.portalId;
};
