import React, { ErrorInfo, PropsWithChildren, ReactElement } from 'react';
import logService from '../../api/infrastructure/logService';

type RetryErrorBoundaryState = {
    error: string | null;
    hasRetried: boolean;
};

type ErrorComponentProps = {
    error: string;
    reset(): void;
};

type RetryErrorBoundaryProps = {
    errorComponent(props: ErrorComponentProps): ReactElement;
};

class CustomErrorBoundary extends React.Component<PropsWithChildren<RetryErrorBoundaryProps>, RetryErrorBoundaryState> {
    constructor(props: RetryErrorBoundaryProps) {
        super(props);
        this.state = {
            error: null,
            hasRetried: false,
        };
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.error(`CustomErrorBoundary caught ${error}, ${errorInfo}`);
        this.setState({ error: ` ${error}, ${errorInfo}` });
        logService.log(error, errorInfo);
    }

    reset = (): void => {
        this.setState({ error: null });
    };

    render(): ReactElement {
        const { error } = this.state;
        const { errorComponent } = this.props;

        if (error) {
            return errorComponent({ error, reset: this.reset });
        }
        return <>{this.props.children}</>;
    }
}

export default CustomErrorBoundary;
