import { Ticket, TicketPriority, TicketStatus, TicketType } from '../../../../../../api/apiTypes/ticketingApiTypes';

interface TicketFormDataInterface {
    assignee?: {
        id: string;
        label: string;
    };
    status: TicketStatus;
    priority: TicketPriority;
    type: string;
    category: string;
    building: { id: string; label: string } | null;
    floor: { id: string; label: string } | null;
}

export const getPublishedCategoryOptions = (
    ticketTypes: TicketType[],
    selectedType: string,
): { id: string; value: string }[] => {
    return ticketTypes
        .filter((type) => {
            return (type.id === selectedType || type.name === selectedType) && type.isPublished;
        })
        .map((type) =>
            type.categories
                .filter((category) => category.isPublished)
                .map((category) => ({
                    id: category.id,
                    value: category.name,
                })),
        )
        .flat();
};

export const getSelectedAndPublishedCategoryOptions = (
    ticketTypes: TicketType[],
    selectedType: string,
    selectedCategory: string | undefined,
): { id: string; value: string }[] => {
    return ticketTypes
        .filter((type) => {
            return type.id === selectedType || type.name === selectedType;
        })
        .map((type) =>
            type.categories
                .filter((category) => category.isPublished || category.id == selectedCategory)
                .map((category) => ({
                    id: category.id,
                    value: category.name,
                    disabled: !category.isPublished,
                })),
        )
        .flat();
};

export const isButtonDisabled = (ticket: Ticket, isUpdatingTicket: boolean): boolean => {
    if (ticket.status === TicketStatus.Closed || ticket.status === TicketStatus.Solved) return true;
    else if (isUpdatingTicket) return true;
    else return false;
};

export const getTicketDetailsFormData = (ticket: Ticket): TicketFormDataInterface => ({
    assignee: ticket.assignee
        ? {
              id: ticket.assignee.id,
              label: `${ticket.assignee.firstName} ${ticket.assignee.lastName}`,
          }
        : undefined,
    status: ticket.status,
    priority: ticket.priority,
    type: ticket.type.name,
    category: ticket.category.id,
    building: ticket.building ? { id: ticket.building.id, label: ticket.building.name } : null,
    floor: ticket.floor ? { id: ticket.floor.id, label: ticket.floor.name } : null,
});
