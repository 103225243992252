import type { Nullable } from '../../utility/nullable.type';
import type { AllergenType } from './shopApiTypes';

export interface IHelpArticle {
    id: number;
    icon: string;
    status: HelpArticleStatus;
    title: string;
    ingress: string;
    body: string;
    publishedFrom: Date;
    publishedTo: Date;
    archived: boolean | null;
    published_at: Date;
    created_at: Date;
    updated_at: Date;
    tags: ITags[];
}

export interface ITags {
    id: number;
    name: string;
    published_at: Date;
    created_at: Date;
    updated_at: Date;
}

export type RolesType = string[] | null;

export interface ArticleDto {
    title: string;
    content: string;
    publishedAt: Date | null;
    coverImage: number | null;
    archivedAt: Date | null;
    showCoverImage: boolean;
    isFeatured: boolean;
    author: {
        firstName: string | undefined;
        lastName: string | undefined;
        avatar: string | undefined | null;
    };
    subcategory: number | null;
    alertPush: boolean;
    alertEmail: boolean;
    alertSms: boolean;
    haveAlerted: boolean;
    forceAlert: boolean;
    roles: RolesType;
    positionX: number | null;
    positionY: number | null;
}

export interface AccessEntitiesInterface {
    buildings: string[];
    companies: string[];
    floors: string[];
}

export type AccessEntitiesType = AccessEntitiesInterface | null;
export interface ArticleModel {
    id: number;
    title: string;
    content: string;
    updatedBy: AuthorModel;
    publishedAt: Date | null;
    archivedAt: Date | null;
    updated: Date;
    coverImage: ImageModel | null;
    isPublishedVersion: boolean;
    hasPublishedVersion: boolean;
    subcategory: SubCategoryModel | null;
    showCoverImage: boolean;
    isFeatured: boolean;
    alertPush: boolean;
    alertEmail: boolean;
    alertSms: boolean;
    haveAlerted: boolean;
    forceAlert: boolean;
    status:
        | 'Draft'
        | 'Planned'
        | 'PlannedButChanged'
        | 'Published'
        | 'PublishedButChanged'
        | 'Archived'
        | 'ArchivedButChanged';
    accessEntities: AccessEntitiesType;
    roles: string[] | null;
    positionX: number | null;
    positionY: number | null;
}

export interface AuthorModel {
    id: string;
    userId: string;
    name: string;
    avatar: string;
    articles: string[];
    images: string[];
    firstName: string;
    lastName: string;
}

export interface FormatSizeModel {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path: string;
    size: number;
    width: number;
    height: number;
}
export interface ImageModel {
    id: number;
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats: {
        xlarge: FormatSizeModel;
        large: FormatSizeModel;
        medium: FormatSizeModel;
        small: FormatSizeModel;
        xsmall: FormatSizeModel;
        thumbnail: FormatSizeModel;
    };
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: never; // described as {} in swagger
    related: {
        created_at: Date;
        id: number;
        name: string;
        published_at: Date;
        updated_at: Date;
        userId: string;
        __contentType: string;
    }[];
    created_by: string;
    updated_by: string;
    positionX: number | null;
    positionY: number | null;
}

export interface CategoryModel {
    id: number;
    name: string;
    countArticles?: number;
    children: SubCategoryModel[];
}

export interface CreateCategoryDto extends UpdateCategoryDto {
    children: SubcategoryDto[];
}

export interface UpdateCategoryDto {
    name: string;
}

export interface SubCategoryModel {
    id: number;
    name: string;
    countArticles?: number;
    parent: number;
}

export interface GetSubcategoryModel extends Omit<SubCategoryModel, 'parent'> {
    parent: Omit<CategoryModel, 'children'>;
}

export interface SubcategoryDto {
    name: string;
}

export type HelpArticleStatus = 'Active';

export type GetArticlesStatusOptions = 'draft' | 'published' | 'archived' | 'planned' | null;

interface DailyMenuCommon {
    title: string;
    description: string;
    date: string;
}

export interface DailyMenuWithAllergens extends DailyMenuCommon {
    allergens: string[];
}

export interface DailyMenuWithAllergensTypeAndDate extends Omit<DailyMenuCommon, 'date'> {
    allergens: AllergenType[];
    date: Date;
}

export type ContentConfigModel = {
    isDefaultFoodMenuCoverImage: boolean;
    foodMenuCoverImage: ImageModel | null;
    foodMenuOrderButton: {
        visibility: boolean;
        labelText: string | null;
        buttonUrl: string | null;
    };
    frontPageButtons:
        | {
              text: string | null;
              subText: string | null;
              url: string | null;
              icon: string | null;
              englishText: string | null;
              englishSubText: string | null;
              disableIfMissingServices: { name: string }[];
              id: number;
          }[]
        | undefined;
    showArticleAuthor: boolean | null;
    id: string;
};

export interface PortalContentConfigCoverImage {
    foodMenuCoverImage: ImageModel | null;
}

export interface CountUsersByAccessAndRolesDto extends Nullable<AccessEntitiesInterface> {
    roles: RolesType;
}

export enum SortingDateType {
    New = 'Nyeste',
    Old = 'Eldste',
}
