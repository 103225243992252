import { AxiosError, AxiosResponse } from 'axios';
import type { MutationResultPair, QueryResult } from 'react-query';
import { useMutation, useQuery, useQueryCache } from 'react-query';
import { AccessEntitiesType, Approver, Building, UpdateRoom } from '../../../api/apiTypes/bookingApiTypes';
import {
    getBuildings,
    updateAccessGroupRoom,
    updateAccessibilityRoom,
    updateApproversRoom,
    updatePriceRoom,
    updateRoom,
} from '../../../api/bookingApi';
import { roomAccessControlKey } from '../../Admin/UsersAdmin/components/UserAdministrationSidesheet/ApiQueries/useAccessControlInfo';
import { useInvalidateMeetingBookings } from './useMeetingBookings';

const MeetingRoomKey = 'meetingRoom';
export const useBuildings = (): QueryResult<Building[], string> => {
    return useQuery([MeetingRoomKey], async () => (await getBuildings()).data, { staleTime: 1000 * 60 * 5 });
};

export const useUpdateRoom = (): MutationResultPair<
    AxiosResponse<never>,
    string | AxiosError,
    [string, UpdateRoom],
    unknown
> => {
    const cache = useQueryCache();
    const invalidateMeetingBookings = useInvalidateMeetingBookings();
    return useMutation(([roomId, data]: [string, UpdateRoom]) => updateRoom(roomId, data), {
        onSuccess: () => {
            invalidateMeetingBookings();
            cache.invalidateQueries(MeetingRoomKey);
        },
    });
};

export const useUpdateApproversRoom = (): MutationResultPair<
    AxiosResponse<never>,
    string | AxiosError,
    { meetingRoomId: string; approversList: Approver[]; needsApproval: boolean },
    never
> => {
    const cache = useQueryCache();
    const invalidateMeetingBookings = useInvalidateMeetingBookings();
    return useMutation(
        ({ meetingRoomId, approversList, needsApproval }) =>
            updateApproversRoom(meetingRoomId, approversList, needsApproval),
        {
            onSuccess: () => {
                invalidateMeetingBookings();
                cache?.invalidateQueries(MeetingRoomKey);
            },
        },
    );
};
export const useUpdateAccessibilityRoom = (): MutationResultPair<
    AxiosResponse<never>,
    string | AxiosError,
    { meetingRoomId: string; data: AccessEntitiesType },
    never
> => {
    const cache = useQueryCache();
    const invalidateMeetingBookings = useInvalidateMeetingBookings();
    return useMutation(({ meetingRoomId, data }) => updateAccessibilityRoom(meetingRoomId, data), {
        onSuccess: () => {
            invalidateMeetingBookings();
            cache?.invalidateQueries(MeetingRoomKey);
        },
    });
};
export const useUpdateAccessGroupRoom = (): MutationResultPair<
    AxiosResponse<never>,
    string | AxiosError,
    { meetingRoomId: string; data: string[] },
    never
> => {
    const cache = useQueryCache();
    const invalidateMeetingBookings = useInvalidateMeetingBookings();
    return useMutation(({ meetingRoomId, data }) => updateAccessGroupRoom(meetingRoomId, data), {
        onSuccess: () => {
            invalidateMeetingBookings();
            cache?.invalidateQueries(MeetingRoomKey);
            cache?.invalidateQueries(roomAccessControlKey);
        },
    });
};

export const useUpdatePriceRoom = (): MutationResultPair<
    AxiosResponse<never>,
    string | AxiosError,
    { meetingRoomId: string; pricePerHour: number },
    never
> => {
    const cache = useQueryCache();
    const invalidateMeetingBookings = useInvalidateMeetingBookings();
    return useMutation(({ pricePerHour, meetingRoomId }) => updatePriceRoom(meetingRoomId, pricePerHour), {
        onSuccess: () => {
            invalidateMeetingBookings();
            cache?.invalidateQueries(MeetingRoomKey);
        },
    });
};
