export const getApimUrl = (): string => {
    return process.env['REACT_APP_APIM'] ?? '';
};

export const getApplicationInsightsConnectionString = (): string => {
    return process.env['REACT_APP_APPLICATIONINSIGHTS_CONNECTION_STRING'] ?? '';
};

export const getIdentityAppName = (): string => {
    return process.env['REACT_APP_IDENTITY_APP_NAME'] ?? '';
};

export const getIdentityAuthority = (): string => {
    return process.env['REACT_APP_IDENTITY_AUTHORITY'] ?? '';
};
