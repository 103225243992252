import React, { ChangeEvent, ReactNode, SyntheticEvent, useEffect, useRef, useState } from 'react';
import axios, { AxiosError, type AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import type { MutateFunction } from 'react-query';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { Message, Icon } from 'semantic-ui-react';
import styled from 'styled-components';
import { ImageModel } from '../../api/apiTypes/cmsApiTypes';
import { UploadedTicketImage } from '../../api/apiTypes/ticketingApiTypes';
import { GhostPrimaryButton, PrimaryButton, Modal, SvgIcon, TertiaryButton, ModalType } from '../../components';
import { ImageView } from '../../modules/Ticketing/components/common';
import { useDragFile } from '../../utility';
import { MobileViewBreakpoint } from '../ScreenSizeBreakPoints';
import FileDropZoneComponent from './FileDropZone';
import { validateImage } from './utils';

const SelectedImage = styled.img`
    width: 100%;
    object-fit: contain;
    height: 22.142rem;
    background-color: #f2f2f2;
`;

type ModalContentWrapperProps = { showImage: boolean; height?: string; imageSelected: boolean };
const ModalContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    ${({ showImage }: ModalContentWrapperProps) => (showImage ? 'margin: 2.857rem' : 'margin: min(4vh, 4rem)')};
    position: relative;
    cursor: ${({ showImage }: ModalContentWrapperProps) => (showImage ? 'grab' : 'pointer')};
`;

const ErrorMessageValidation = styled(Message)`
    && {
        position: absolute;
        top: 1rem;
        margin: 1rem;
        width: 95%;
    }
`;

const ErrorMessageUpload = styled(ErrorMessageValidation)`
    && {
        position: sticky;
    }
`;

const DisplayedImageWrapper = styled.div`
    width: 100%;
`;

const ErrorMessageIcon = styled(Icon)`
    color: var(--error-color);
`;

const ActionContainer = styled.div`
    width: 100%;
    display: inline-grid;
    justify-content: flex-end;
    grid-template-columns: repeat(3, max-content);

    &&& > button {
        margin-left: 1.7rem;
    }

    @media (max-width: ${MobileViewBreakpoint}px) {
        grid-template-columns: repeat(2, 1fr);
        grid-row-gap: 0.7rem;
    }
`;

const RevertButton = styled(TertiaryButton)`
    & > svg {
        margin-right: 0.57rem;
    }

    @media (max-width: ${MobileViewBreakpoint}px) {
        padding: 0.8rem 1.1rem;
        grid-column-start: 3;
        grid-column-end: 1;
        margin: 0 auto;
    }
`;

interface ImageUploaderProps {
    onClose(): void;
    uploadImage: MutateFunction<AxiosResponse<ImageModel[] | UploadedTicketImage>, AxiosError<string>, FormData, never>;
    onUploadImageSuccess?(image: ImageModel | string): void;
    filePickCallback?(file: File): void;
    isUploadingImage?: boolean;
    initialSelectedImage?: string;
    maxImageSize?: number;
    refProp?: string;
    refId?: string;
    field?: string;
    transformComponentShow?: boolean;
    height?: string;
    handleRemoveImage?(imageName: string): void;
}

export enum FileState {
    Loading = 'loading',
    Success = 'success',
    Error = 'error',
    Finished = 'finished',
}
export interface FileObject {
    file: File;
    fileState: FileState;
}

enum ModalContent {
    FileDropZone,
    DisplayedImage,
}

const ImageUploader: React.FC<React.PropsWithChildren<ImageUploaderProps>> = ({
    onClose,
    uploadImage,
    onUploadImageSuccess,
    filePickCallback,
    isUploadingImage = false,
    initialSelectedImage,
    maxImageSize = 200,
    refProp: ref = 'portal-user',
    refId = '1',
    field = 'images',
    transformComponentShow,
    height,
    handleRemoveImage,
}: ImageUploaderProps): JSX.Element => {
    const [selectedImage, setSelectedImage] = useState<File | null | string>(initialSelectedImage ?? null);
    const fileDropZone = useRef<HTMLDivElement>(null);
    const fileInputField = useRef<HTMLInputElement>(null);
    const { dragging, droppedFile: droppedImage } = useDragFile(fileDropZone);
    const [errorMessageValidation, setErrorMessageValidation] = useState<string | null>(null);
    const [errorMessageUploadingImage, setErrorMessageUploadingImage] = useState<string | null>(null);
    const [fullScreenView, setFullScreenView] = useState(false);
    const [positions, setPositions] = useState({ positionX: 0, positionY: 0 });
    const [modalContent, setModalContent] = useState<ModalContent>(ModalContent.FileDropZone);
    const { t } = useTranslation('common');
    const selectedImageRef = useRef<HTMLImageElement>(null);
    const modalComponentRef = useRef<HTMLDivElement>(null);
    const [displayedImage, setDisplayedImage] = useState<null | string>(null);
    const [fileObjects, setFileObjects] = useState<FileObject[]>([]);

    useEffect(() => {
        if (selectedImage instanceof File) {
            const imageCheck = validateImage(selectedImage, maxImageSize);
            switch (imageCheck) {
                case 'WrongFormat':
                    setErrorMessageValidation(t('selectedFileNotSupportedFormat'));
                    break;
                case 'ToBig':
                    setErrorMessageValidation(t('fileAcceptableSize', { maxImageSize }));
                    break;
                default:
                    setErrorMessageValidation(null);
                    break;
            }
            if (fileObjects.some((i) => i.file.name === selectedImage.name)) {
                setErrorMessageValidation(t('selectedFileDuplicatedName'));
            }
        }
    }, [errorMessageUploadingImage, selectedImage, maxImageSize]);

    useEffect(() => {
        if (droppedImage) {
            setSelectedImage(droppedImage);
            setModalContent(ModalContent.DisplayedImage);
        }
    }, [droppedImage]);

    useEffect(() => {
        window.addEventListener('mouseup', dropHandler, true);
        return () => {
            window.removeEventListener('mouseup', dropHandler, true);
        };
    }, []);

    useEffect(() => {
        const image = selectedImage instanceof File ? URL.createObjectURL(selectedImage) : selectedImage;
        setDisplayedImage(image);
    }, [selectedImage]);

    const imageUploadChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
        if (e.target.files) {
            const image = e.target.files[0];
            setModalContent(ModalContent.DisplayedImage);
            setSelectedImage(image);
        }
    };

    const handleChangeFileState = (fileName: string, newState: FileState): void => {
        setFileObjects((prev) =>
            prev.map((obj) => (obj.file.name === fileName ? { ...obj, fileState: newState } : obj)),
        );
    };

    const handleCreateFileObject = (file: File): FileObject => ({
        file: file,
        fileState: FileState.Loading,
    });

    const handlePrepareImageToUpload = (imageFile: File): FormData => {
        const image = new FormData();
        image.append('files', imageFile);
        image.append('ref', ref);
        image.append('refId', refId);
        image.append('field', field);
        image.append('positionX', String(positions.positionX));
        image.append('positionY', String(positions.positionY));
        return image;
    };

    const handleUploadImage = async (imageFormData: FormData, imageFile: File): Promise<void> => {
        const positionCoordinates = {
            positionX: positions.positionX,
            positionY: positions.positionY,
        };
        await uploadImage(imageFormData, {
            onSuccess: (response: AxiosResponse<ImageModel[] | UploadedTicketImage>) => {
                if (onUploadImageSuccess) {
                    if (Array.isArray(response.data)) {
                        const dataWithImagePositions = {
                            ...response.data[0],
                            ...positionCoordinates,
                        };
                        onUploadImageSuccess(dataWithImagePositions);
                    } else if (typeof response.data === 'string') {
                        onUploadImageSuccess(response.data);
                    }
                }
                if (filePickCallback) filePickCallback(imageFile);

                handleChangeFileState(imageFile.name, FileState.Success);
                setTimeout(() => {
                    handleChangeFileState(imageFile.name, FileState.Finished);
                }, 0);
            },
            onError: (error) => {
                if (axios.isCancel(error)) onGoBack();
                handleChangeFileState(imageFile.name, FileState.Error);
            },
        });
    };

    const submitImageHandler = async (e: SyntheticEvent): Promise<void> => {
        e.preventDefault();
        if (selectedImage instanceof File) {
            const newFileObject = handleCreateFileObject(selectedImage);
            setFileObjects((prev) => [...prev, newFileObject]);
            const imageFormData = handlePrepareImageToUpload(selectedImage);
            setModalContent(ModalContent.FileDropZone);
            await handleUploadImage(imageFormData, selectedImage);
            setSelectedImage(null);
        }
    };

    const refetchImageHandler = async (fileName: string) => {
        const file = fileObjects?.find((fileObj) => fileObj.file.name === fileName)?.file;

        if (file && handleRemoveImage) {
            const formDataFile = handlePrepareImageToUpload(file);
            handleChangeFileState(fileName, FileState.Loading);

            await handleUploadImage(formDataFile, file);
        }
    };

    const onImageUploadButtonClickHandler = () => fileInputField.current?.click();

    const handleImageClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        displayedImage && setFullScreenView(!fullScreenView);
    };

    const handleClose = () => {
        setFullScreenView(false);
        onClose();
    };

    const onGoBack = (): void => {
        setFullScreenView(false);
        setSelectedImage(null);
        setErrorMessageValidation(null);
        setErrorMessageUploadingImage(null);
        setModalContent(ModalContent.FileDropZone);
    };

    const removeHandler = (fileName: string) => {
        handleRemoveImage && handleRemoveImage(fileName);
        setFileObjects((prev) => prev.filter((obj) => obj.file.name !== fileName));
    };

    const dropHandler = () => {
        const initialPositionCoordinates = modalComponentRef.current?.getBoundingClientRect();
        const initial = {
            positionX: initialPositionCoordinates?.x ?? 0,
            positionY: initialPositionCoordinates?.y ?? 0,
        };

        const data = selectedImageRef.current?.getBoundingClientRect();
        if (data) {
            const positions = {
                positionX: 0,
                positionY: ((data.y - initial.positionY) / data.height) * 100,
            };
            setPositions(positions);
        }
    };
    const bottomContent = (): ReactNode => {
        const isDoneButtonDisabledDisplayedImage = !!errorMessageValidation || !selectedImage || isUploadingImage;
        const isDoneButtonDisabledDropZone =
            fileObjects.some((fileObject) => fileObject.fileState === FileState.Error) ||
            !fileObjects.length ||
            isUploadingImage;

        return (
            <ActionContainer>
                {modalContent === ModalContent.DisplayedImage ? (
                    <>
                        <RevertButton onClick={onGoBack} disabled={isUploadingImage}>
                            <SvgIcon name="UndoIcon" />
                            {t('reset')}
                        </RevertButton>

                        <GhostPrimaryButton onClick={handleClose} disabled={isUploadingImage}>
                            {t('cancelButton')}
                        </GhostPrimaryButton>
                        <PrimaryButton onClick={submitImageHandler} disabled={isDoneButtonDisabledDisplayedImage}>
                            {t('buttonDone')}
                        </PrimaryButton>
                    </>
                ) : null}

                {modalContent === ModalContent.FileDropZone ? (
                    <>
                        <GhostPrimaryButton onClick={handleClose}>{t('cancelButton')}</GhostPrimaryButton>
                        <PrimaryButton onClick={handleClose} disabled={isDoneButtonDisabledDropZone}>
                            {t('buttonDone')}
                        </PrimaryButton>
                    </>
                ) : null}
            </ActionContainer>
        );
    };

    return (
        <Modal
            type={ModalType.Medium}
            open={true}
            hideCloseButton={isUploadingImage}
            onClose={handleClose}
            title={t('uploadingImageFile')}
            bottomContent={bottomContent()}
        >
            <ModalContentWrapper
                showImage={!displayedImage && !errorMessageValidation}
                height={height}
                ref={modalComponentRef}
                imageSelected={!!displayedImage}
            >
                {modalContent === ModalContent.DisplayedImage && (
                    <DisplayedImageWrapper>
                        {errorMessageValidation && (
                            <ErrorMessageUpload error>
                                <ErrorMessageIcon name="exclamation circle" />
                                {errorMessageValidation}
                            </ErrorMessageUpload>
                        )}
                        {transformComponentShow ? (
                            <TransformWrapper
                                initialScale={1}
                                initialPositionX={0}
                                initialPositionY={0}
                                wheel={{ disabled: true }}
                                doubleClick={{ disabled: false, step: 0.4 }}
                                pinch={{
                                    disabled: false,
                                }}
                            >
                                <TransformComponent wrapperStyle={{ width: '100%' }} contentStyle={{ width: '100%' }}>
                                    <SelectedImage
                                        ref={selectedImageRef}
                                        src={displayedImage ?? ''}
                                        onClick={handleImageClick}
                                    />
                                </TransformComponent>
                            </TransformWrapper>
                        ) : (
                            <SelectedImage src={displayedImage ?? ''} onClick={handleImageClick} />
                        )}
                    </DisplayedImageWrapper>
                )}

                {modalContent === ModalContent.FileDropZone && (
                    <FileDropZoneComponent
                        fileDropZone={fileDropZone}
                        dragging={dragging}
                        height={height}
                        maxImageSize={maxImageSize}
                        fileInputField={fileInputField}
                        imageUploadChangeHandler={imageUploadChangeHandler}
                        onImageUploadButtonClickHandler={onImageUploadButtonClickHandler}
                        handleRemoveImage={removeHandler}
                        handleRefetchImage={refetchImageHandler}
                        uploadedFiles={fileObjects || []}
                    />
                )}
                {selectedImage instanceof File && fullScreenView ? (
                    <ImageView
                        image={{ imageFileName: selectedImage.name, imageUrl: URL.createObjectURL(selectedImage) }}
                        alt={selectedImage.name}
                        onlyFullView
                        onClose={() => setFullScreenView(false)}
                    />
                ) : null}
            </ModalContentWrapper>
        </Modal>
    );
};

export default ImageUploader;
